import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import { Button } from "../../../controls";
import { IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import AddEditGroup from "./AddEditGroup";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";

const Groups = () => {
  const [updData, setUpdData] = useState();
  const [showForm, setShowForm] = useState(false);
  const { selOutlet, setLoader } = useContext(AppContext);
  const [groups, setGroups] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    if (selOutlet.id) {
      API(controller.CstmrGroup + action.GetAllByOrg).getById(
        selOutlet.orgId,
        (res) => {
          res.status === 200 && setGroups([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this group?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setGroups((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        API(controller.CstmrGroup).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
          className="mr-5 p-5"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Group</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "name", label: "Name" },
          { id: "cstmr", label: "Number of Customer" },
          { id: "desc", label: "Description" },
          { id: "createDate", label: "Create Date" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={groups}
      />

      {/* Add Follow Up */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditGroup
            {...{ setShowForm: setShowForm, updData, setAlert, setGroups }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Groups;
