import { ClearRounded } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Button } from "../../../controls";

const SelectVariantProd = (props) => {
  const { setShowForm, prod, handleSelVarProd, setAlert } = props;
  const [value, setValue] = useState();
  const [activeAttr, setActiveAttr] = useState(0);
  const [selAttrVals, setSelAttrVals] = useState([]);

  useEffect(() => {
    prod &&
      setValue({
        prodType: prod.prodType,
        prodId: prod.id,
        prod: prod,
        qty: 1,
        price: prod.rtlPrice,
        totalPrice: prod.rtlPrice,
        discount: "",
        note: "",
      });
    return () => {};
  }, [prod]);

  const handleClose = () => {
    setShowForm(false);
    setActiveAttr(0);
    setSelAttrVals([]);
  };

  const handleSel = (val) => {
    setSelAttrVals((j) => [...j, val.name]);
    //check is that last attribute?
    if (prod.prodVarAttrs && prod.prodVarAttrs.length === activeAttr + 1) {
      var _selAttrVals = [...selAttrVals, val.name];
      var _selVaraint = prod.prodVariants.find((j) => {
        switch (_selAttrVals.length) {
          case 1:
            return j.attrVal1 === _selAttrVals[0];
          case 2:
            return (
              j.attrVal1 === _selAttrVals[0] && j.attrVal2 === _selAttrVals[1]
            );
          case 3:
            return (
              j.attrVal1 === _selAttrVals[0] &&
              j.attrVal2 === _selAttrVals[1] &&
              j.attrVal3 === _selAttrVals[2]
            );
          default:
            return null;
        }
      });

      if (_selVaraint && _selVaraint.id)
        handleSelVarProd(
          { ...value, variantId: _selVaraint.id, variant: _selVaraint },
          handleClose
        );
      else {
        handleClose();
        setAlert({
          isOpen: true,
          type: "warning",
          title: "Something is wrong",
          subTitle:
            "Selected variant data not found. Please refresh the page and try again.",
        });
      }

      handleClose();
    } else setActiveAttr(activeAttr + 1);
  };

  return (
    <div className="p-15 d-grid g-15">
      <Fragment>
        {prod && prod.prodVarAttrs && (
          <>
            {/* Header */}
            <div className="mb-5 d-flex j-between a-center">
              <div className="d-flex a-center g-10">
                <span className="fs-16 gray-color mb-5 ">
                  {/* Size &gt; Color &gt; Metal */}
                  {prod.prodVarAttrs.map((_attr, i) => (
                    <Fragment key={i}>
                      <span
                        className={`${
                          activeAttr === i && "primary-color fw-5"
                        }`}
                      >
                        {_attr.attr.name}
                      </span>
                      {prod.prodVarAttrs.length - 1 > i && " > "}
                    </Fragment>
                  ))}
                </span>
              </div>
              <div>
                <IconButton onClick={handleClose}>
                  <ClearRounded />
                </IconButton>
              </div>
            </div>
            {/* End Header */}

            {/* Body */}
            <div className="d-grid g-15">
              <Grid container spacing={2}>
                <Grid item xs={12} className="pb-10">
                  <span className="gray-color fs-20">
                    Select Size for “<span className="fw-5">{prod.name}</span> ”
                  </span>
                </Grid>

                {prod.prodVarAttrs[activeAttr].values.map((attrVal, i) => (
                  <Grid
                    item
                    xs={12}
                    md={
                      prod.prodVarAttrs[activeAttr].values.length === 2 ? 6 : 4
                    }
                    key={i}
                  >
                    <Button
                      onClick={() => handleSel(attrVal)}
                      className="p-10 w-100pr h-60 fs-20 fw-4"
                      size="large"
                      variant="outlined"
                      sx={{ minWidth: "180px" }}
                    >
                      {attrVal.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>
            {/* End Body */}
          </>
        )}
      </Fragment>
    </div>
  );
};

export default SelectVariantProd;
