import { Grid } from "@mui/material";
import { Tabs } from "../../../layout";
import React, { useEffect, useState } from "react";
import CustomerInfo from "./CustomerInfo";
import Details from "./Details";
import StoreCredit from "./StoreCredit";
import Accounts from "./Accounts";
import Notes from "./Notes";
import { useParams } from "react-router-dom";
import { API, action, controller } from "../../../api/api";
import { DropLi } from "../../../hooks";

const ViewCstmr = () => {
  const { id } = useParams();
  const [cstmr, setCstmr] = useState();
  const { Gender, AccBalTypes } = DropLi();
  const [gender, setGender] = useState("");
  const [accBalType, setAccBalType] = useState("");

  useEffect(() => {
    id &&
      API(controller.Customer + action.GetById).getById(id, (res) => {
        res && res.status === 200 && setCstmr(res.result);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (cstmr) {
      // get name of the gender
      var _gender = Gender.find((j) => j.id === cstmr.gender);
      setGender(_gender ? _gender.label : "");

      // get name of the account balance type
      var _balType = AccBalTypes.find((j) => j.id === cstmr.accBalType);
      setAccBalType(_balType ? _balType.label : "");
    }
    return () => {
      setGender("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstmr]);

  console.log("cstmr: ", cstmr);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8.5}>
          <div>
            <Tabs
              header={[
                { label: "Details" },
                { label: "Store Credit" },
                { label: "Account" },
                { label: "Note" },
              ]}
            >
              <Details {...{ cstmr, gender, accBalType }} />
              <StoreCredit />
              <Accounts />
              <Notes {...{ cstmr }} />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <CustomerInfo {...{ cstmr, gender, accBalType }} />
        </Grid>
      </Grid>
    </>
  );
};

export default ViewCstmr;
