import React from "react";
import {
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  FormHelperText,
} from "@mui/material";

export default function InputCheck(props) {
  const {
    label,
    checked,
    defaultChecked,
    name,
    onChange,
    size = "medium",
    error = null,
    variant = "checkbox-primary",
    className,
    ...other
  } = props;
  return (
    <FormGroup className="d-contents">
      <FormControlLabel
        onChange={onChange}
        className={variant + " text-select-disable gray-color " + className}
        name={name}
        control={
          <MuiCheckbox
            size={size}
            checked={checked}
            defaultChecked={defaultChecked}
            className="p-5"
          />
        }
        label={label}
        {...other}
      />
      {error && (
        <FormHelperText className="color-danger">{error}</FormHelperText>
      )}
    </FormGroup>
  );
}
