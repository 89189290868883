import { RandomCode } from "./Method";

export default function Model() {
  const LoginMdl = () => ({
    email: "",
    password: "",
  });

  const ChgPassMdl = () => ({
    oldPass: "",
    password: "",
    confirmPass: "",
  });

  const SellMdl = () => ({
    id: 0,
    sellItems: [],
    paymentType: "",
    taxPct: "",
    discount: "",
    subtotal: "",
    totalPay: "",
    received: "",
    change: "",
    taxId: "",
    note: "",

    outletId: "",
  });

  const OrgMdl = () => ({
    // Org Info.
    id: 0,
    orgName: "",
    defTax: "",
    logo: "",
    logoFile: "",

    // Contact Info.
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    website: "",
    twitter: "",

    // Physical Address
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",

    isPostalAddress: true,

    // Postal Address
    pAddress1: "",
    pAddress2: "",
    pCity: "",
    pState: "",
    pZip: "",
  });

  const CashMdl = () => ({
    id: "",
    type: 1,
    amount: "",
    note: "",
  });

  const RefundMdl = () => ({
    id: "",
    prodCond: 1,
    invoiceId: "",
    note: "",
  });

  const CartProdMdl = () => ({
    qty: "",
    price: "",
    discount: "",
    note: "",
  });

  const ServiceMdl = () => ({
    id: 0,
    cstmrId: "",
    phone: "",
    email: "",
    date: "",
    hours: "",
    minutes: "",
    note: "",
  });

  const QuoteMdl = () => ({
    id: 0,
    cstmrId: "",
    phone: "",
    email: "",
    note: "",
  });

  const UserMdl = () => ({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",

    outletId: "",
    role: "",
    accStatus: "",
    password: "",
    confirmPass: "",

    dailyTarget: "",
    weeklyTarget: "",
    monthlyTarget: "",

    gender: "",
    dob: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",

    photo: "",
    photoFile: "",
  });

  const PayTypeMdl = () => ({
    id: 0,
    name: "",
    getway: "",
  });

  const TaxMdl = () => ({
    id: 0,
    name: "",
    rate: "",
  });

  const OutletMdl = () => ({
    id: 0,
    outletName: "",
    taxId: "",
    phone: "",
    email: "",

    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });

  const CstmrGroupMdl = () => ({
    id: 0,
    name: "",
    desc: "",
    createDate: "",
  });

  const CstmrMdl = () => ({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    cstmrGroupId: "",
    dob: "",

    country: "",
    city: "",
    state: "",
    zip: "",
    address1: "",
    address2: "",

    cstmrCode: "",
    company: "",

    website: "",
    twitter: "",
    note: "",
    photo: "",
    photoFile: "",
    accBalType: "",
    balLimit: "",
    taxId: "",

    // card details
    nameOnCard: "",
    cardNumber: "",
    expiryDate: "",
    securityCode: "",
  });

  const StockMdl = () => ({
    id: 0,
    splrId: "",
    recipientOutletId: "",
    splrInv: "",
    deliveryDate: new Date().toLocaleDateString(),
    orderNum: RandomCode(12),
    stockProds: [],
    note: "",
    type: "",
  });

  const SpecialOrderMdl = () => ({
    id: 0,
    cstmrId: "",
    phone: "",
    email: "",
    deliveryDate: "",
    note: "",
  });

  const SplrReturnMdl = () => ({
    id: 0,
    splrId: "",
    note: "",
  });

  const ReturnStockMdl = () => ({
    id: 0,
    returnNo: "",
    deliveryDue: new Date().toLocaleDateString(),
    deliveryTo: "",
    returnForm: "",
    nameOrRefe: `Return - ${new Date().toDateString()}`,
    splrInv: "",
    files: [],
    returnFiles: [],
  });

  const StockTransMdl = () => ({
    id: 0,
    fromOutlet: "",
    toOutlet: "",
    refe: `Stock Transfer 1225 - ${new Date().toDateString()}`,
    date: "",
  });

  const SupplierMdl = () => ({
    id: 0,
    splrName: "",
    defMarkup: "",
    desc: "",
    company: "",

    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    fax: "",
    gender: "",
    website: "",

    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    zip: "",

    photo: "",
    photoFile: "",
  });

  const CategoryMdl = () => ({
    id: 0,
    name: "",
    desc: "",
    parentCatId: "",
    isMainCat: true,
    img: "",
    imgFile: "",
  });

  const BrandMdl = () => ({
    id: 0,
    name: "",
    desc: "",
  });

  const TagMdl = () => ({
    id: 0,
    name: "",
    desc: "",
  });

  const ForgotMdl = () => ({
    email: "",
    forgotStep: "",
    verCode: "",
    password: "",
    confirmPass: "",
  });

  const ProdMdl = () => ({
    id: 0,
    name: "",
    brandId: "",
    prodTags: [],
    catId: "",
    subCatId: "",
    desc: "",

    taxId: "",
    taxPrice: "",
    splrId: "",
    splrCode: "",
    splrPrice: "",
    markup: "",
    rtlPrice: "",

    trackInv: true,
    inventories: [],

    prodType: 1,
    skuCode: "",
    skuType: 1,

    // Variant Product
    prodVariants: [],
    prodVarAttrs: [{ id: 1, attrId: "", value: [] }],

    // Composit Product
    compositProds: [],

    imgs: [],
    imgFiles: [],
  });

  const VariantProdMdl = () => ({
    // Not Mapped
    key: 0,

    id: 0,
    splrCode: "",
    splrPrice: "",
    rtlPrice: "",
    skuType: 1,
    skuCode: "",
    enabled: true,

    taxId: "",
    taxPrice: "",

    attrId1: "",
    attrVal1: "",
    attrId2: "",
    attrVal2: "",
    attrId3: "",
    attrVal3: "",

    inventories: [],

    imgs: [],
    imgFiles: [],
  });

  return {
    LoginMdl,
    ChgPassMdl,
    ForgotMdl,
    ProdMdl,
    CategoryMdl,
    SupplierMdl,
    BrandMdl,
    StockMdl,
    ReturnStockMdl,
    StockTransMdl,
    CstmrMdl,
    CstmrGroupMdl,
    OutletMdl,
    PayTypeMdl,
    TaxMdl,
    UserMdl,
    CartProdMdl,
    QuoteMdl,
    ServiceMdl,
    CashMdl,
    SellMdl,
    SpecialOrderMdl,
    SplrReturnMdl,
    RefundMdl,
    OrgMdl,
    TagMdl,
    VariantProdMdl,
  };
}
