import React from "react";

const StoreCredit = () => {
  return (
    <div className="fs-18 gray-color fs-italic">
      This section is wait for the backend.
    </div>
  );
};

export default StoreCredit;
