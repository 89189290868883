import { PersonRounded } from "@mui/icons-material";
import { Grid, Grow, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input, InputPass } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { Form } from "../../layout";
import { ToFormData } from "../../hooks/Method";
import { API, action, controller } from "../../api/api";
import { AppContext } from "../../App";

const Login = () => {
  const { LoginMdl } = Model();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAuthUser } = useContext(AppContext);
  const { values, errors, setErrors, handleInpChg } = UseForm(LoginMdl);
  const [errMsg, setErrMsg] = useState("");

  const validateForm = () => {
    let temp = {};
    temp.email = values.email !== "" ? "" : "Email is required.";
    temp.password = values.password !== "" ? "" : "Password is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        if (res) {
          switch (res.status) {
            case 403:
              setErrors({
                ...errors,
                [res.error.key]: res.error.msg,
              });
              break;
            case 401:
              setErrMsg(res.msg);
              break;
            case 200:
              navigate("/home");
              setAuthUser(res.result);
              break;
            default:
              break;
          }
        }
        setLoading(false);
      };
      API(controller.Auth + action.Login).post(ToFormData(values), onComplete);
    }
  };
  return (
    <Grow in timeout={1000}>
      <Container maxWidth="xl">
        <div className="d-flex j-center a-center h-100vh">
          <div style={{ maxWidth: "400px" }}>
            <Paper className="p-15 bs-primaryMD">
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="tx-center mb-10 mt-20 d-flex j-center">
                      <div
                        className="w-fit p-5"
                        style={{
                          backgroundColor: "var(--primaryXS-color)",
                          borderRadius: "50%",
                        }}
                      >
                        <PersonRounded
                          className="primary-color"
                          sx={{ fontSize: "80px" }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="tx-center">
                    <Typography color="error">{errMsg}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInpChg}
                      error={errors.email}
                      className="input-primary w-100pr"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputPass
                      label="Password"
                      name="password"
                      value={values.password}
                      onChange={handleInpChg}
                      error={errors.password}
                      className="input-primary w-100pr"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="tx-right"
                    sx={{ pt: "15px !important", pb: "15px", fontSize: "15px" }}
                  >
                    <Link
                      to="forgot"
                      className="gray-color tx-und-none tx-upp hove-primary"
                    >
                      Forgot Password?
                    </Link>
                  </Grid>
                  <Grid item xs={12} className="text-center">
                    <Button
                      loading={loading}
                      type="submit"
                      fullWidth
                      size="large"
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </div>
        </div>
      </Container>
    </Grow>
  );
};

export default Login;
