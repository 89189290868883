import React from "react";
import { Grid } from "@mui/material";

const ProdDtls = () => {
  return (
    <div className="pt-5 bg-white br-12 p-10">
      <Grid container spacing={3} className="p-15 dark-color mb-10">
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
            Product Id:
          </span>
          <span className="fs-16 fw-4 gray-color">{1001}</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Produt Name:</span>
          <span className="fs-16 fw-4 gray-color gray-color">
            Comfy Cone Extender Panel Black
          </span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">SKU Code:</span>
          <span className="fs-16 fw-4 gray-color">856437002106</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Brand:</span>
          <span className="fs-16 fw-4 gray-color">Product Brand Name</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Category:</span>
          <span className="fs-16 fw-4 gray-color">Category Name</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Product Type:</span>
          <span className="fs-16 fw-4 gray-color">Standard Product</span>
        </Grid>

        <Grid item xs={12} md={6} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color">Description:</span>
          <span className="fs-16 fw-4 gray-color">
            Here your product description
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProdDtls;
