import { Grid } from "@mui/material";
import { Tabs } from "../../../layout";
import React from "react";
import ProdInv from "./ProdInv";
import ProdPricing from "./ProdPricing";
import ProdDtls from "./ProdDtls";

const ViewProd = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <div>
            <Tabs
              header={[
                { label: "Inventory" },
                { label: "Pricing" },
                { label: "Details" },
              ]}
            >
              <ProdInv />
              <ProdPricing />
              <ProdDtls />
            </Tabs>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewProd;
