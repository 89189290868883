import React, { useState } from "react";
import { Input } from "../../../controls";
import { IconButton, InputAdornment } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";

const SearchBox = () => {
  const [searchVal, setSearchVal] = useState("");
  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };
  return (
    <div className="p-5">
      <Input
        label="Enter product name or scan"
        name="search"
        variant="outlined"
        value={searchVal}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchBox;
