import React from "react";
import { Dashboard } from "../layout";
import { Header, NavMenu } from "../components/body";
import { Navigate, Route, Routes } from "react-router-dom";
import { logo } from "../assets";

import {
  Home,
  AddEditProd,
  Products,
  Categories,
  Suppliers,
  AddEditSplr,
  Brands,
  Stocks,
  OrderAndReceiveForm,
  ReturnStockForm,
  StockTransForm,
  RetailReport,
  AddEditCstmr,
  Customers,
  ViewCstmr,
  Groups,
  SettingsForm,
  Outlets,
  AddEditOutlet,
  PaymentTypes,
  Taxes,
  Users,
  AddEditUser,
  Sell,
  OpenCloseReg,
  SalesHistory,
  CashMgmts,
  Status,
  Quotes,
  SpecialOrderForm,
  SpecialOrders,
  SplrReturns,
  SplrReturnForm,
  Tags,
  ProdAttrs,
} from ".";
import ViewProd from "./Catalog/Product/ViewProd";
import ViewOrder from "./Inventory/Stock/ViewOrder";

const SiteRoute = () => {
  return (
    <Dashboard menu={<NavMenu />} header={<Header />} logo={logo}>
      <Routes>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="/home" element={<Home />} />

        <Route path="/sell/*" element={<Sell />} />
        <Route path="/status" element={<Status />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/cash_management" element={<CashMgmts />} />
        <Route path="/sales_history" element={<SalesHistory />} />
        <Route path="/open_or_colse" element={<OpenCloseReg />} />

        <Route path="/products" element={<Products />} />
        <Route path="/view_product/:id" element={<ViewProd />} />
        <Route path="/product_form">
          <Route path=":id" element={<AddEditProd />} />
          <Route path="" element={<AddEditProd />} />
        </Route>

        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/supplier_form">
          <Route path=":id" element={<AddEditSplr />} />
          <Route path="" element={<AddEditSplr />} />
        </Route>

        <Route path="/groups" element={<Groups />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/view_customer/:id" element={<ViewCstmr />} />
        <Route path="/customer_form">
          <Route path=":id" element={<AddEditCstmr />} />
          <Route path="" element={<AddEditCstmr />} />
        </Route>

        <Route path="/transfer_stock_form">
          <Route path=":id" element={<StockTransForm />} />
          <Route path="" element={<StockTransForm />} />
        </Route>

        <Route path="/order_stock_form">
          <Route path=":id" element={<OrderAndReceiveForm />} />
          <Route path="" element={<OrderAndReceiveForm />} />
        </Route>
        <Route path="/return_stock_form">
          <Route path=":id" element={<ReturnStockForm />} />
          <Route path="" element={<ReturnStockForm />} />
        </Route>
        <Route path="/receive_stock_form">
          <Route
            path=":id"
            element={<OrderAndReceiveForm isReceiveForm={true} />}
          />
          <Route
            path=""
            element={<OrderAndReceiveForm isReceiveForm={true} />}
          />
        </Route>
        <Route path="/supplier_form">
          <Route path=":id" element={<AddEditSplr />} />
          <Route path="" element={<AddEditSplr />} />
        </Route>
        <Route path="/supplier_form">
          <Route path=":id" element={<AddEditSplr />} />
          <Route path="" element={<AddEditSplr />} />
        </Route>
        <Route path="/supplier_form">
          <Route path=":id" element={<AddEditSplr />} />
          <Route path="" element={<AddEditSplr />} />
        </Route>

        <Route path="/brands" element={<Brands />} />
        <Route path="/product_attributes" element={<ProdAttrs />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/product_category" element={<Categories />} />
        <Route path="/stock_control" element={<Stocks />} />
        <Route path="/view_order/:id" element={<ViewOrder />} />

        <Route path="/retail_dashboard" element={<RetailReport />} />
        <Route path="/settings_form" element={<SettingsForm />} />

        <Route path="/outlets" element={<Outlets />} />
        <Route path="/outlet_form">
          <Route path=":id" element={<AddEditOutlet />} />
          <Route path="" element={<AddEditOutlet />} />
        </Route>

        <Route path="/payment_types" element={<PaymentTypes />} />
        <Route path="/taxes" element={<Taxes />} />

        <Route path="/users" element={<Users />} />
        <Route
          path="/edit_my_account/:id"
          element={<AddEditUser fromMyAcc={true} />}
        />
        <Route path="/user_form">
          <Route path=":id" element={<AddEditUser />} />
          <Route path="" element={<AddEditUser />} />
        </Route>

        <Route path="/special_orders" element={<SpecialOrders />} />
        <Route path="/special_order_form">
          <Route path=":id" element={<SpecialOrderForm />} />
          <Route path="" element={<SpecialOrderForm />} />
        </Route>

        <Route path="/supplier_returns" element={<SplrReturns />} />
        <Route path="/supplier_return_form">
          <Route path=":id" element={<SplrReturnForm />} />
          <Route path="" element={<SplrReturnForm />} />
        </Route>
      </Routes>
    </Dashboard>
  );
};

export default SiteRoute;
