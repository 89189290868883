import {
  BarChartRounded,
  HomeRounded,
  InventoryRounded,
  PeopleRounded,
  SellRounded,
  SettingsSuggestRounded,
} from "@mui/icons-material";
import { NavItem } from "../../layout";

const NavMenu = () => {
  return (
    <div>
      <NavItem
        title="Home"
        path="home"
        icon={<HomeRounded className="fs-22" />}
      />
      <NavItem
        title="Sell"
        icon={<SellRounded className="fs-22" />}
        child={[
          {
            title: "Sell",
            path: "sell",
          },
          {
            title: "Open/Close",
            path: "open_or_colse",
          },
          {
            title: "Sales History",
            path: "sales_history",
          },
          {
            title: "Cash Management",
            path: "cash_management",
          },
          {
            title: "Status",
            path: "status",
          },
          {
            title: "Quotes",
            path: "quotes",
          },
        ]}
      />
      <NavItem
        title="Reports"
        icon={<BarChartRounded className="fs-22" />}
        child={[
          {
            title: "Retail Dashboard",
            path: "retail_dashboard",
          },
          {
            title: "Sales Reports",
            path: "sales_reports",
          },
          {
            title: "Inventory Reports",
            path: "inventory_reports",
          },
          {
            title: "Payments Reports",
            path: "payments_reports",
          },
          {
            title: "Register Closures",
            path: "register_closures",
          },
          {
            title: "Gift Card Reports",
            path: "gift_card_reports",
          },
          {
            title: "Store Card Reports",
            path: "store_credit_reports",
          },
          {
            title: "Tax Reports",
            path: "tax_reports",
          },
        ]}
      />
      <NavItem
        title="Catalog"
        icon={<SellRounded className="fs-22" />}
        child={[
          {
            title: "Products",
            path: "products",
          },
          {
            title: "Promotions",
            path: "promotions",
          },

          {
            title: "Suppliers",
            path: "suppliers",
          },
          {
            title: "Product Category",
            path: "product_category",
          },
          {
            title: "Brands",
            path: "brands",
          },

          {
            title: "Tags",
            path: "tags",
          },
          {
            title: "Product Attributes",
            path: "product_attributes",
          },
        ]}
      />
      <NavItem
        title="Inventory"
        icon={<InventoryRounded className="fs-22" />}
        child={[
          {
            title: "Stock Control",
            path: "stock_control",
          },
          {
            title: "Special Orders",
            path: "special_orders",
          },
          {
            title: "Supplier Returns",
            path: "supplier_returns",
          },
          {
            title: "Inventory Counts",
            path: "inventory_counts",
          },
          {
            title: "Fulfillments",
            path: "fulfillments",
          },
          {
            title: "Serial Numbers",
            path: "serial_numbers",
          },
        ]}
      />
      <NavItem
        title="Customer"
        icon={<PeopleRounded className="fs-22" />}
        child={[
          {
            title: "Customers",
            path: "customers",
          },
          {
            title: "Groups",
            path: "groups",
          },
        ]}
      />
      <NavItem
        title="Settings"
        icon={<SettingsSuggestRounded className="fs-22" />}
        child={[
          {
            title: "General",
            path: "settings_form",
          },
          {
            title: "Outlets",
            path: "outlets",
          },
          {
            title: "Payment Types",
            path: "payment_types",
          },
          {
            title: "Taxes",
            path: "taxes",
          },
          {
            title: "Users",
            path: "users",
          },
        ]}
      />
    </div>
  );
};

export default NavMenu;
