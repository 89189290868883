import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../../layout";
import { Button, InputAuto, Select } from "../../../controls";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import { EditRounded, RemoveRedEyeRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";

const Stocks = () => {
  const navigate = useNavigate();
  const { selOutlet, setLoader } = useContext(AppContext);
  const [typeFltVal, setTypeFltVal] = useState(1);
  const [outletFltVal, setOutletFltVal] = useState("");
  const [stocks, setStocks] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    if (selOutlet.id) {
      API(controller.Stock + action.GetAllByOutlet).getById(
        selOutlet.orgId,
        (res) => {
          res && res.status === 200 && setStocks([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  const handleAddEdit = (id) => {
    navigate(`/order_stock_form/${id || ""}`);
  };

  const handleViewOrder = (id) => {
    navigate(`/view_order/${id}`);
  };

  const handleTypeFltr = (e) => {
    setTypeFltVal(e.target.value);
  };
  const handleOutletFltr = (e) => {
    setOutletFltVal(e.target.value);
  };

  const handleOrder = () => {
    navigate("/order_stock_form");
  };
  const handleReceive = () => {
    navigate("/receive_stock_form");
  };

  // const handleReturn = () => {
  //   navigate("/return_stock_form");
  // };
  // const handleTransfer = () => {
  //   navigate("/transfer_stock_form");
  // };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="View">
        <IconButton
          onClick={() => handleViewOrder(val.id)}
          size="small"
          color="primary"
          className="ml-5 mr-5 p-5"
        >
          <RemoveRedEyeRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {val.status === 1 && (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleAddEdit(val.id)}
              size="small"
              color="primary"
              className="mr-5 p-5"
            >
              <EditRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-10">
      <Button onClick={() => handleOrder()}>Order Stock</Button>
      <Button variant="outlined" onClick={() => handleReceive()}>
        Receive Stock
      </Button>
      {/* <Button variant="text" onClick={() => handleReturn()}>
        Return Stock
      </Button>
      <Button variant="text" onClick={() => handleTransfer()}>
        Transfer Stock
      </Button> */}
    </div>
  );

  return (
    <>
      <Paper className="p-10 mb-15">
        <Box className="g-15" sx={{ display: { lg: "flex", xs: "grid" } }}>
          <Select
            label="Type"
            size="small"
            value={typeFltVal}
            fullWidth
            onChange={handleTypeFltr}
            options={[
              { id: 1, label: "All Orders" },
              { id: 1, label: "Open Orders" },
              { id: 2, label: "Open Returns" },
              { id: 3, label: "Sent Orders" },
              { id: 4, label: "Dispatched Orders" },
              { id: 5, label: "Sent Returns" },
              { id: 6, label: "Recevied Orders" },
              { id: 7, label: "Overdue Orders" },
              { id: 8, label: "Cancelled Orders" },
              { id: 9, label: "Failled Orders" },
            ]}
          />
          <InputAuto
            label="Outlet"
            size="small"
            value={outletFltVal}
            fullWidth
            onChange={handleOutletFltr}
            options={[
              { id: 1, label: "Outlet 1" },
              { id: 2, label: "Outlet 2" },
              { id: 3, label: "Outlet 3" },
            ]}
          />
        </Box>
      </Paper>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "number", label: "Number" },
          { id: "from", label: "From" },
          { id: "to", label: "To" },
          { id: "orderStatus", label: "Status" },
          { id: "date", label: "Date Create/Update" },
          { id: "qty", label: "Total Qty" },
          { id: "cost", label: "Total Cost" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={stocks.map((j) => {
          var splrCompany = j.splr && j.splr.company;
          var splrName = j.splr && j.splr.firstName + " " + j.splr.lastName;
          var totalCost = j.stockProds
            .map((x) => x.qty * x.price)
            .reduce((x, y) => x + y, 0)
            .toFixed(2);

          return {
            ...j,
            number: (
              <div className="pb-5 pt-5 d-grid">
                <span>{j.orderNum}</span>

                <span className="fs-12">
                  Due Date:{" "}
                  <span className="fw-5">
                    {new Date(j.deliveryDate).toDateString()}
                  </span>
                </span>
              </div>
            ),
            splrName: splrName,
            splrCompany: splrCompany,
            from: (
              <div className="d-grid">
                <span>{splrCompany}</span>
                <span className="fs-14">{splrName}</span>
              </div>
            ),
            to: j.recipientOutlet && j.recipientOutlet.outletName,
            orderStatus: (
              <div className="tx-center fs-14 white-color w-fit">
                {j.status === 1 ? (
                  <div className="bg-primary br-25 pl-10 pr-10">
                    <span>Open</span>
                  </div>
                ) : j.status === 2 ? (
                  <div className="bg-success br-25 pl-10 pr-10">
                    <span>Received</span>
                  </div>
                ) : (
                  j.status === 3 && (
                    <div className="bg-warning br-25 pl-10 pr-10">
                      <span>Cancelled</span>
                    </div>
                  )
                )}
              </div>
            ),

            date: (
              <div className="d-grid">
                <span className="fs-14">
                  Create: {new Date(j.createDate).toDateString()}
                </span>
                {j.updateDate && (
                  <span className="fs-14">
                    Update: {new Date(j.updateDate).toDateString()}
                  </span>
                )}
              </div>
            ),
            qty: j.stockProds
              .map((x) => x.qty)
              .reduce((x, y) => x + y, 0)
              .toLocaleString(),
            cost: "$ " + totalCost,
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Stocks;
