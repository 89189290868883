import { ClearRounded } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Button, Input, InputAuto } from "../../../controls";
import { Form } from "../../../layout";
import { Model, UseForm } from "../../../hooks";

const QuoteForm = (props) => {
  const { setShowForm, setAlert } = props;
  const { QuoteMdl } = Model();
  const [loading, setLoading] = useState(false);
  const { values, errors, setErrors, handleInpChg } = UseForm(QuoteMdl);

  const validateForm = () => {
    let temp = {};
    temp.cstmrId = values.cstmrId !== "" ? "" : "Customer is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.email = values.email !== "" ? "" : "Email is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(false);
      setAlert({
        isOpen: true,
        type: "success",
        title: "Success",
        subTitle: "Quote Created Successfully.",
      });
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <div className="p-15 bg-aliceblue">
      <Form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="mb-15 d-flex j-between a-center">
              <div className="d-flex a-center g-10">
                <span className="fs-22 gray-color mb-5 border-left tx-upp">
                  Create Quote
                </span>
              </div>
              <div>
                <IconButton onClick={handleClose}>
                  <ClearRounded />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <InputAuto
              label="Customer"
              name="cstmrId"
              value={values.cstmrId}
              error={errors.cstmrId}
              required={true}
              onChange={handleInpChg}
              options={customers}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Phone"
              name="phone"
              value={values.phone}
              error={errors.phone}
              required={true}
              onChange={handleInpChg}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Email"
              name="email"
              value={values.email}
              error={errors.email}
              required={true}
              onChange={handleInpChg}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Note"
              name="note"
              value={values.note}
              error={errors.note}
              multiline
              rows={3}
              onChange={handleInpChg}
            />
          </Grid>
          <Grid item xs={12} className="tx-right">
            <Button
              sx={{ minWidth: "150px" }}
              size="large"
              loading={loading}
              onClick={handleClose}
            >
              Complete Quote
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default QuoteForm;

const customers = [
  { id: 1, label: "Customer 1" },
  { id: 2, label: "Customer 2" },
  { id: 3, label: "Customer 3" },
  { id: 4, label: "Customer 4" },
  { id: 5, label: "Customer 5" },
];
