import React, { useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import { Button } from "../../../controls";
import AddCash from "./AddCash";
import { Avatar } from "@mui/material";

const CashMgmts = () => {
  const [updData, setUpdData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleAdd = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const HeaderComp = () => (
    <div className="d-flex g-10">
      <Button onClick={() => handleAdd()}>Add Cash</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "date", label: "Date" },
          { id: "user", label: "User" },
          { id: "reason", label: "Reasons" },
          { id: "amount", label: "Transactions ($)" },
        ]}
        tblData={records.map((item) => {
          return {
            ...item,
            user: (
              <div className="d-flex g-15">
                <Avatar src="" className="shadow-lg" />
                <div className="d-grid">
                  <span>{item.name}</span>
                  <a className="fs-12 link" href={`mailto:${item.email}`}>
                    {item.email}
                  </a>
                </div>
              </div>
            ),
            reason: (
              <div className="d-grid">
                <span>{item.reason}</span>
                <span className="fs-12">{item.note}</span>
              </div>
            ),
            amount: (
              <span className={item.amount < 0 ? "error-color" : "gray-color"}>
                {item.amount}
              </span>
            ),
          };
        })}
      />

      {/* Add Follow Up */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddCash {...{ setShowForm: setShowForm, updData, setAlert }} />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default CashMgmts;

const records = [
  {
    date: "Sep 6, 2023, 11:18 PM",
    email: "jihad@a.com",
    name: "Jihad Hassan",
    reason: "Petty cash in",
    note: "Your note about this transaction",
    amount: 200,
  },
  {
    date: "Sep 6, 2023, 11:18 PM",
    email: "jihad@a.com",
    name: "Jihad Hassan",
    reason: "Cash out",
    note: "Your note about this transaction",
    amount: -200,
  },
  {
    date: "Sep 6, 2023, 11:18 PM",
    email: "jihad@a.com",
    name: "Jihad Hassan",
    reason: "Opening float",
    note: "Your note about this transaction",
    amount: 200,
  },
];
