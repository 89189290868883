import { Dialog, Table } from "../../../layout";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { ReplyRounded, VisibilityRounded } from "@mui/icons-material";
import { useState } from "react";
import InvDetails from "../Sell/ViewInvDetails/InvDetails";
import { Select } from "../../../controls";
import ViewSale from "../SaleHistory/ViewSale";

const Quotes = (props) => {
  const [viewSale, setViewSale] = useState(false);
  const [showInvDtls, setShowInvDtls] = useState(false);
  const [invDtlViewData, setInvDtlViewData] = useState();
  const [fltrVal, setFltrVal] = useState(1);

  const handleView = (data) => {
    setViewSale(true);
  };

  const handleViewDtl = (data) => {
    setInvDtlViewData(data);
    setShowInvDtls(true);
  };

  const handleFltr = (e) => {
    setFltrVal(e.target.value);
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="View Details">
        <IconButton
          onClick={handleView}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Details">
        <IconButton size="small" color="primary" className="p-5">
          <ReplyRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <Select
      variant="outlined"
      size="small"
      fullWidth
      value={fltrVal}
      onChange={handleFltr}
      options={[
        { id: 1, label: "All Quotes" },
        { id: 2, label: "Open" },
        { id: 3, label: "Completed" },
      ]}
    />
  );

  return (
    <>
      <div className="mb-10">
        <span className="border-left fs-22 gray-color tx-upp">Quotes</span>
      </div>

      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "quote", label: "Quote" },
          { id: "cstmr", label: "Customer" },
          { id: "servedBy", label: "Served by	" },
          { id: "note", label: "Note" },
          { id: "amt", label: "Quote Total" },
          { id: "status", label: "Status" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={sales.map((item) => {
          return {
            ...item,
            quote: (
              <div className="d-grid">
                <span className="">{item.invId}</span>
                <span className="fs-12">{item.date}</span>
              </div>
            ),
            cstmr: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <Avatar className="bg-primary">JH</Avatar>
                <div className="d-grid">
                  <span className="">{item.cstmr}</span>
                  <span className="fs-12">{item.outlet}</span>
                </div>
              </div>
            ),
            servedBy: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <Avatar className="bg-primary">JH</Avatar>
                <div className="d-grid">
                  <span className="">{item.cstmr}</span>
                  <span className="fs-12">{item.outlet}</span>
                </div>
              </div>
            ),
          };
        })}
      />

      <Dialog show={viewSale} onClose={false} maxWidth="md">
        <ViewSale {...{ setShowForm: setViewSale, handleViewDtl }} />
      </Dialog>

      {/* Inventory Details */}
      <Dialog show={showInvDtls} onClose={false} maxWidth="md">
        <InvDetails
          {...{ setShowForm: setShowInvDtls, data: invDtlViewData }}
        />
      </Dialog>
    </>
  );
};

export default Quotes;

const sales = [
  {
    id: 1,
    invId: "1001",
    date: "Sep 4, 2023, 8:33 PM",
    cstmr: "Jihad Hassan",
    outlet: "Main Outlet",
    note: "ABCD EEE",
    amt: "$0.00",
    status: "Open",
  },
  {
    id: 1,
    invId: "1001",
    date: "Sep 4, 2023, 8:33 PM",
    cstmr: "Jihad Hassan",
    outlet: "Main Outlet",
    note: "ABCD EEE",
    amt: "$0.00",
    status: "Open",
  },
  {
    id: 1,
    invId: "1001",
    date: "Sep 4, 2023, 8:33 PM",
    cstmr: "Jihad Hassan",
    outlet: "Main Outlet",
    note: "ABCD EEE",
    amt: "$0.00",
    status: "Open",
  },
  {
    id: 1,
    invId: "1001",
    date: "Sep 4, 2023, 8:33 PM",
    cstmr: "Jihad Hassan",
    outlet: "Main Outlet",
    note: "ABCD EEE",
    amt: "$0.00",
    status: "Open",
  },
  {
    id: 1,
    invId: "1001",
    date: "Sep 4, 2023, 8:33 PM",
    cstmr: "Jihad Hassan",
    outlet: "Main Outlet",
    note: "ABCD EEE",
    amt: "$0.00",
    status: "Open",
  },
];
