import { Grid } from "@mui/material";
import React from "react";
import { Button } from "../../../controls";

const RegisterDtls = () => {
  return (
    <div className="bg-white br-15 shadow-xs p-15">
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-10 d-flex j-between">
          <div>
            <span className="tx-upp fs-22 fw-4 gray-color border-left">
              Register Details
            </span>
          </div>
          <div>
            <Button fullWidth type="submit">
              Close Register
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
            Outlet
          </span>
          <span className="fs-16 fw-4 gray-color">ABC Super Shop</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
            Register
          </span>
          <span className="fs-16 fw-4 gray-color">Main Register</span>
        </Grid>
        <Grid item xs={12} md={4} className="d-grid">
          <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
            Opening time
          </span>
          <span className="fs-16 fw-4 gray-color">
            Sun, 27 Aug 2023, 1:51am
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterDtls;
