import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const ProdPricing = () => {
  return (
    <>
      <TableContainer className="bg-white br-12 shadow-xs">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="fs-16 gray-color">Outlet</TableCell>
              <TableCell className="fs-16 gray-color" align="right">
                Supplier Price
              </TableCell>
              <TableCell className="fs-16 gray-color" align="right">
                Retail Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas.map((data, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="fs-16 gray-color"
                >
                  {data.outlet}
                </TableCell>
                <TableCell className="fs-16 gray-color" align="right">
                  {data.splrPrice}
                </TableCell>
                <TableCell className="fs-16 gray-color" align="right">
                  {data.rtlPrice}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProdPricing;

const datas = [
  { outlet: "Main Outlet", splrPrice: "$50", rtlPrice: "$100" },
  { outlet: "Healthy Furs Sand Canyon	", splrPrice: "$50", rtlPrice: "$100" },
  { outlet: "Long Beach	", splrPrice: "$50", rtlPrice: "$100" },
];
