import React from "react";
import Widgets from "./Widgets";
import { Grid } from "@mui/material";
import TopSellingProd from "./TopSellingProd";
import TopCustomer from "./TopCustomer";
import SalesAvg from "./SalesAvg";
import OutletProg from "./OutletProg";
import HomeRepFilter from "./HomeRepFilter";

const Home = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HomeRepFilter />
        </Grid>
        <Grid item xs={12}>
          <Widgets />
        </Grid>
        <Grid item xs={12} md={4}>
          <SalesAvg />
        </Grid>
        <Grid item xs={12} md={4}>
          <TopSellingProd />
        </Grid>
        <Grid item xs={12} md={4}>
          <TopCustomer />
        </Grid>
        <Grid item xs={12}>
          <OutletProg />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
