import { IconButton, Paper } from "@mui/material";

const Widget = (props) => {
  const { icon, number = "0.00", title } = props;
  return (
    <Paper className="p-20 pb-30 pt-30 bg-blue primary-color bg-primaryXS d-flex a-center g-20 shadow-sm bs-primaryMD c-pointer">
      <div>
        <IconButton color="primary">{icon}</IconButton>
      </div>
      <div className="d-grid a-center">
        <span className="fs-28 fw-5 mb-5">{number}</span>
        <span className="primary-color fs-14">{title}</span>
      </div>
    </Paper>
  );
};

export default Widget;
