import { Divider, Paper } from "@mui/material";
import React from "react";
import SaleHistory from "./SaleHistory";

const Accounts = () => {
  return (
    <Paper className="pt-5 p-15">
      <div className="d-grid g-10 mt-5">
        <div className="gray-color d-flex j-between">
          <span className="fw-6">Allow on account balance:</span>
          <span>Yes, unlimited</span>
        </div>
        <Divider />
        <div className="gray-color d-flex j-between">
          <span className="fw-6">Balance:</span>
          <span>$0.00</span>
        </div>
        <Divider />
        <div className="gray-color d-flex j-between">
          <span className="fw-6">Total spent:</span>
          <span>$0.00</span>
        </div>
        <Divider />
        <div className="gray-color d-flex j-between">
          <span className="fw-6">Last 12 months:</span>
          <span>$0.00</span>
        </div>
      </div>
      <div className="d-flex a-center mt-30">
        <span
          className="gray-color tx-upp fw-5 fs-14"
          style={{ minWidth: "70px" }}
        >
          History
        </span>
        <div className="bs-secondary w-100pr" />
      </div>
      <div className="mt-30">
        <SaleHistory />
      </div>
    </Paper>
  );
};

export default Accounts;
