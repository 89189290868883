import { Paper } from "@mui/material";
import React from "react";

const Notes = ({ cstmr }) => {
  return (
    <Paper className="pt-5 p-15">
      <p className="p pre-wrap">{cstmr && cstmr.note}</p>
    </Paper>
  );
};

export default Notes;
