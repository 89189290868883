import { ClearRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { Button, Input } from "../../../controls";

const ParkSale = (props) => {
  const { setShowForm } = props;
  const [note, setNote] = useState("");

  const handleClose = () => {
    setShowForm(false);
  };

  const handleNoteChg = (e) => {
    setNote(e.target.value);
  };

  return (
    <div className="p-15 d-grid g-15">
      <div className="mb-15 d-flex j-between a-center">
        <div className="d-flex a-center g-10">
          <span className="fs-22 gray-color mb-5">Add a note to the sale</span>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <ClearRounded />
          </IconButton>
        </div>
      </div>
      <div className="d-grid g-15">
        <span className="gray-color">
          You are about to park this sale. Add a note so it can be identified by
          the next person who continues this sale.
        </span>
        <Input
          label="Note"
          value={note}
          variant="outlined"
          multiline
          rows={3}
          onChange={handleNoteChg}
        />
      </div>

      <div className="tx-right">
        <Button sx={{ minWidth: "150px" }} size="large" onClick={handleClose}>
          Park Sale
        </Button>
      </div>
    </div>
  );
};

export default ParkSale;
