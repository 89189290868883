import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";

const AddEditBrand = (props) => {
  const { setAlert, setShowForm, updData } = props;
  const { CashType } = DropLi();
  const { CashMdl } = Model();
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(CashMdl);

  useEffect(() => {
    updData && setValues((x) => ({ ...x, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : "Name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(false);
      setAlert({
        isOpen: true,
        type: "success",
        title: "Success",
        subTitle: `Subscription Type ${
          updData ? "Updated" : "Added"
        } Successfully.`,
      });
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <Grid container spacing={2} className="p-15">
            <Grid item xs={12} className="tx-center p-20 pt-30 w-100pr mb-20">
              <span className="fw-4 fs-26 tx-upp gray-color">
                {updData ? "Update" : "Add"} Cash
              </span>
              <div className="pos-relative d-flex j-center mt-5">
                <div className="border-bottom w-60" />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label="Type"
                name="type"
                value={values.type}
                error={errors.type}
                required={true}
                onChange={handleInpChg}
                options={CashType}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Amount"
                name="amount"
                placeholder="$0.00"
                value={values.amount}
                error={errors.amount}
                required={true}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Note"
                name="note"
                value={values.note}
                error={errors.note}
                multiline
                rows={3}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex g-10 mt-20">
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button fullWidth size="large" loading={loading} type="submit">
                  Add Cash
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

export default AddEditBrand;
