import { CloseRounded } from "@mui/icons-material";
import { Box, Divider, IconButton, InputAdornment, Paper } from "@mui/material";
import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputCheck } from "../../../controls";
import { no_img, prod1, prod2, prod3, prod4 } from "../../../assets";
import { Accordion, Dialog } from "../../../layout";
import CartProdForm from "./CartProdForm";
import InvDetails from "./ViewInvDetails/InvDetails";
import { CartContext } from "./Sell";

const Cart = () => {
  const navigate = useNavigate();
  const { cartData } = useContext(CartContext);
  const [showInvDtls, setShowInvDtls] = useState(false);
  const [invDtlViewData, setInvDtlViewData] = useState();
  const [percentDis, setPercentDis] = useState(true);
  const [disVal, setDisVal] = useState("");
  const [note, setNote] = useState("");

  const handleViewDtl = (data) => {
    setInvDtlViewData(data);
    setShowInvDtls(true);
  };

  const handleDis = (e) => {
    setDisVal(e.target.value);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const handleChgDisType = (e) => {
    setPercentDis(e.target.checked);
  };

  console.log("cart_data: ", cartData);

  return (
    <>
      <Paper className="br-8 bg-white pt-10">
        {/* Cart Items */}
        <Box
          sx={{
            height: "calc(100vh - 315px)",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Accordion
            items={cartData.sellItems.map((item, i) => ({
              header: <Header item={item} />,
              body: <CartProdForm {...{ handleViewDtl, data: item }} />,
            }))}
          />
          <Box className="w-100pr">
            <Accordion
              items={[
                {
                  header: (
                    <div className="gray-color w-100pr tx-upp d-flex j-between w-100pr">
                      <span className="fs-16 fw-5">Add</span>
                      <span className="mr-10">Discount & Note</span>
                    </div>
                  ),
                  body: (
                    <div className="d-grid g-15">
                      <div className="d-grid g-5">
                        <Input
                          label="Discount"
                          name="discount"
                          type="number"
                          variant="outlined"
                          size="small"
                          value={disVal}
                          onChange={handleDis}
                          InputProps={
                            !percentDis
                              ? {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span className="fw-6 fs-18">$</span>
                                    </InputAdornment>
                                  ),
                                }
                              : {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span className="fw-6 fs-18">%</span>
                                    </InputAdornment>
                                  ),
                                }
                          }
                        />
                        <div className="ml-10">
                          <InputCheck
                            label="Give discount as percent"
                            name="percentDis"
                            type="number"
                            size="small"
                            checked={percentDis}
                            onChange={handleChgDisType}
                          />
                        </div>
                      </div>
                      <Input
                        label="Note"
                        name="note"
                        value={note}
                        variant="outlined"
                        size="small"
                        multiline
                        rows={2}
                        onChange={handleNote}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </Box>
        </Box>

        {/* Accounts */}
        <Box className="mt-20 gray-color">
          <Box className="d-flex j-between p-10">
            <span className="fs-18">Subtotal:</span>
            <span className="fs-18">$0.00</span>
          </Box>
          <Divider />
          <Box className="d-flex j-between mb-10 p-10">
            <span className="fs-18">Tax (1%):</span>
            <span className="fs-18">$0.00</span>
          </Box>
          <div className="p-10">
            <Button
              onClick={() => navigate("checkout")}
              className="d-flex j-between"
              sx={{ p: "12px" }}
              size="large"
              fullWidth
            >
              <div>
                <span className="fs-20">Pay</span>
                <span className="fs-12 ml-10 fw-4">0 item</span>
              </div>

              <span className="fs-20">$0.00</span>
            </Button>
          </div>
        </Box>
      </Paper>

      {/* Inventory Details */}
      <Dialog show={showInvDtls} onClose={false} maxWidth="md">
        <InvDetails
          {...{ setShowForm: setShowInvDtls, data: invDtlViewData }}
        />
      </Dialog>
    </>
  );
};

export default Cart;

const Header = ({ item }) => {
  const rmvCartItem = (e, item) => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        className="mb-5 mt-5 w-100pr"
        style={
          {
            // width: "calc(100vh - 380px)",
          }
        }
      >
        <div className="d-flex g-15 j-between a-center">
          <div>
            <div className="d-flex g-10">
              <div>
                <img
                  src={
                    item.prod && item.prod.imgs[0]
                      ? item.prod.imgs[0].imgUrl
                      : no_img
                  }
                  className="br-4 h-40 w-40 obj-cover"
                  alt="product_img"
                />
              </div>

              <div className="d-grid">
                <span
                  className="fs-14"
                  style={{ maxWidth: "calc(100vh - 500px)" }}
                >
                  {item.prod.name}
                </span>
                <span className="fs-12">Qty: 0 | Gold</span>
              </div>
            </div>
          </div>
          <div className="d-flex a-center tx- g-15">
            <span className="fw-5 gray-color"> ${item.price}</span>
            <IconButton
              onClick={(e) => rmvCartItem(e, item)}
              size="small"
              color="error"
              className="p-0"
            >
              <CloseRounded className="fs-18 m-5" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

const cartItems = [
  { id: 1, img: prod1, name: "Product Name", qty: 20, price: 50 },
  { id: 2, img: prod2, name: "Product Name", qty: 20, price: 50 },
  { id: 3, img: prod3, name: "Product Name", qty: 20, price: 50 },
  { id: 4, img: prod4, name: "Product Name", qty: 20, price: 50 },
  // { id: 5, img: prod5, name: "Product Name", qty: 20, price: 50 },
  // { id: 6, img: prod6, name: "Product Name", qty: 20, price: 50 },
  // { id: 7, img: prod7, name: "Product Name", qty: 20, price: 50 },
  // { id: 8, img: prod8, name: "Product Name", qty: 20, price: 50 },
];
