export default function DropLi() {
  const Gender = [
    { id: 1, label: "Male" },
    { id: 2, label: "Female" },
    { id: 3, label: "Other" },
  ];
  const ProdRetType = [
    { id: 1, label: "Sellable" },
    { id: 2, label: "Unsellable" },
  ];

  const ProdType = [
    { id: 1, label: "Standard Product", subtitle: "This product is a single SKU with its own inventory.", },
    { id: 2, label: "Variant Product",subtitle: "This is a group of similar products with different attributes like size or color. Each variant is a unique SKU with its own inventory.", },
    { id: 3, label: "Composite Product", subtitle: "This product is made up of specified quantities of one or more products. A composite is a single SKU but uses the inventory of the products within it.", },
  ];

  const SalesType = [
    { id: 1, label: "All Sales" },
    { id: 2, label: "Layby" },
    { id: 3, label: "On Account" },
    { id: 3, label: "Parked" },
    { id: 3, label: "Delivery, unfulfilled" },
    { id: 3, label: "Pickup, unfulfilled" },
    { id: 3, label: "Service, pending" },
    { id: 3, label: "Completed" },
    { id: 3, label: "Layby, completed" },
    { id: 3, label: "On account, completed" },
    { id: 3, label: "Delivery, completed" },
    { id: 3, label: "Pickup, completed" },
    { id: 3, label: "Service, completed" },
    { id: 3, label: "Voided" },
  ];
  const AccBalTypes = [
    { id: 1, label: "No" },
    { id: 2, label: "Yes, With no balance limit" },
    { id: 3, label: "Yes, But with a balance limit" },
  ];
  const CashType = [
    { id: 1, label: "Cash In" },
    { id: 2, label: "Petty Cash In" },
    { id: 3, label: "Cash Out" },
    { id: 4, label: "Petty Cash Out" },
  ];
  const PeriodicType = [
    { id: 1, label: "Today" },
    { id: 2, label: "Yesterday " },
    { id: 3, label: "This Week" },
    { id: 4, label: "Last Week" },
    { id: 5, label: "This Month" },
    { id: 6, label: "Last Month" },
    { id: 7, label: "This Year" },
    { id: 8, label: "Last Year" },
  ];
  const Role = [
    { id: 1, label: "Admin" },
    { id: 2, label: "Manager" },
    { id: 3, label: "Cashier" },
  ];
  const SkuTypes = [
    { id: 1, label: "Auto-Generated" },
    { id: 2, label: "Custom" },
    { id: 3, label: "EAN" },
    { id: 4, label: "ISBN" },
    { id: 5, label: "ITF" },
    { id: 6, label: "JAN" },
    { id: 7, label: "UPC" },
  ];

  const Status = [
    { id: 1, label: "Active" },
    { id: 2, label: "Inactive" },
  ];
  const PayMethod = [
    { id: 1, label: "Cash" },
    { id: 2, label: "Cheque" },
    { id: 3, label: "Card Payment" },
    { id: 4, label: "Online Payment" },
  ];
  const Healths = [
    { id: 1, label: "Blood Pressure" },
    { id: 2, label: "Diabites" },
    { id: 3, label: "Back Pain" },
    { id: 4, label: "Cholestrol" },
    { id: 5, label: "Thyroid" },
    { id: 6, label: "Knee Pain" },
  ];
  const MemberStatus = [
    { id: 1, label: "Respectful" },
    { id: 2, label: "Cleanliness" },
    { id: 3, label: "Empathy" },
    { id: 4, label: "Thoughtfulness" },
    { id: 5, label: "Responsiveness" },
    { id: 6, label: "Mindfulness" },
    { id: 7, label: "Quietness" },
    { id: 8, label: "Generosity" },
    { id: 9, label: "Boundaries" },
    { id: 10, label: "Honesty" },
    { id: 11, label: "Patience" },
    { id: 12, label: "Supportive" },
    { id: 13, label: "Maintaining hygiene" },
    { id: 13, label: "Other" },
  ];

  const PaymentMethods = [
    { id: 1, label: "Cash" },
    { id: 2, label: "Cheque" },
    { id: 3, label: "Internet Banking" },
    { id: 4, label: "Debit Card" },
    { id: 5, label: "Credit Card" },
    { id: 6, label: "Google Pay" },
    { id: 7, label: "Other" },
  ];

  return {
    SkuTypes,
    MemberStatus,
    PaymentMethods,
    Gender,
    Status,
    Healths,
    Role,
    PayMethod,
    PeriodicType,
    AccBalTypes,
    SalesType,
    CashType,
    ProdRetType,
    ProdType,
  };
}
