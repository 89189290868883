import { TextField } from "@mui/material";
import React from "react";

export default function Input(props) {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    error = null,
    required = false,
    fullWidth = true,
    ...other
  } = props;
  return (
    <TextField
      variant={variant || "outlined"}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      fullWidth={fullWidth}
      required={required}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
