import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Details = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className="d-grid g-15">
            <div className="d-grid">
              <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
                Product category
              </span>
              <span className="fs-16 fw-4 gray-color">Fashion</span>
            </div>
            <div className="d-grid">
              <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
                Description
              </span>
              <span className="fs-16 fw-4 gray-color">
                Polycarbonate and Metal Frame. Polycarbonate Lens. Steel Hinges.
                Cat. 3 Lens. 100% UV Protection
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell align="right">Supplier Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {splrs.map((splr, i) => (
                  <TableRow
                    key={i}
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{splr.name}</TableCell>
                    <TableCell align="right">${splr.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;

const splrs = [
  { id: 1, name: "Jihad Hassan", price: "6.20" },
  { id: 2, name: "Stephen", price: "6.20" },
  { id: 3, name: "Jhon", price: "6.20" },
];
