import { ClearRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { Button } from "../../../controls";

const RtvSellConf = (props) => {
  const { setShowForm } = props;
  const handleClose = () => {
    setShowForm(false);
  };
  return (
    <div className="p-15 d-grid g-15">
      <div className="mb-15 d-flex j-between a-center">
        <div className="d-flex a-center g-10">
          <span className="fs-20 gray-color mb-5">
            Hold up! You currently have a sale in progress
          </span>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <ClearRounded />
          </IconButton>
        </div>
      </div>
      <div>
        <span className="gray-color">
          You have a sale on the Sell screen that hasn’t been completed. You can
          choose to return to that sale to complete it, or save that sale and
          continue with this one.
        </span>
      </div>

      <div className="d-flex g-10 mt-20">
        <Button fullWidth variant="text" onClick={handleClose}>
          Return to Sale in Progress
        </Button>
        <Button fullWidth onClick={handleClose}>
          Save and Continue
        </Button>
      </div>
    </div>
  );
};

export default RtvSellConf;
