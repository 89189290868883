import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const InventoryDtl = () => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Outlet</TableCell>

            <TableCell align="right">Current inventory</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            hover
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>Main Outlet </TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
          <TableRow
            hover
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>Anacortes </TableCell>
            <TableCell align="right">0</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryDtl;
