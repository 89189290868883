import React from "react";
import { Select } from "../../controls";
import { useState } from "react";
import DropLi from "../../hooks/DropLi";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const AssociatePerf = () => {
  const { PeriodicType } = DropLi();
  const [fltLocaltion, setFltLocaltion] = useState(1);
  const [fltDate, setFltDate] = useState(1);

  const handleLocation = (e) => {
    setFltLocaltion(e.target.value);
  };

  const handleDate = (e) => {
    setFltDate(e.target.value);
  };

  return (
    <>
      <Paper className="bs-primaryMD">
        <div className="d-flex a-center j-between p-10 mb-10">
          <div>
            <span className="w-100pr fs-18 fw-5 gray-color border-left tx-upp">
              Associate Performance
            </span>
          </div>
          <Box
            className="d-flex g-10"
            sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
          >
            <Select
              size="small"
              sx={{ minWidth: "200px" }}
              variant="outlined"
              value={fltDate}
              onChange={handleDate}
              options={PeriodicType}
            />
            <Select
              size="small"
              sx={{ minWidth: "200px" }}
              variant="outlined"
              value={fltLocaltion}
              onChange={handleLocation}
              options={[
                { id: 1, label: "Location 1" },
                { id: 2, label: "Location 2" },
                { id: 3, label: "Location 3" },
                { id: 4, label: "Location 4" },
                { id: 5, label: "Location 5" },
              ]}
            />
          </Box>
        </div>
        <TableContainer className="mb-10">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className="primary-color nowrap">
                  Sales Rep
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  # Trans
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Total Units
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  UPT
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Net Sales
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  ATV
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Food Sales
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Food % of Retail Sales
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Treat Sales
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Treat % of Retail Sales
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Returns Amount
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Discount Amount
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Promotion Transactions
                </TableCell>
                <TableCell className="primary-color nowrap" align="right">
                  Promo Transactions % of
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((item, i) => (
                <TableRow key={i} hover>
                  <TableCell className="nowrap">{item.salesRep}</TableCell>
                  <TableCell align="right" className="nowrap">
                    {item.trans}
                  </TableCell>
                  <TableCell align="right">{item.totalUnit}</TableCell>
                  <TableCell align="right">{item.upt}</TableCell>
                  <TableCell align="right">{item.netSales}</TableCell>
                  <TableCell align="right">{item.atv}</TableCell>
                  <TableCell align="right">{item.foodSales}</TableCell>
                  <TableCell align="right">{item.foodRetailSales}</TableCell>
                  <TableCell align="right">{item.treatSales}</TableCell>
                  <TableCell align="right">{item.treatRetailSales}</TableCell>
                  <TableCell align="right">{item.returnAmt}</TableCell>
                  <TableCell align="right">{item.discountAmt}</TableCell>
                  <TableCell align="right">{item.promotionTrans}</TableCell>
                  <TableCell align="right">{item.promoTrans}</TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="fw-5"> Total: </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  N/A
                </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  $0.00
                </TableCell>
                <TableCell className="fw-5" align="right">
                  0.00
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default AssociatePerf;

const records = [
  {
    salesRep: "Brandon",
    trans: "60",
    totalUnit: "239",
    upt: "5.66",
    netSales: "4,448",
    atv: "74.14",
    foodSales: "2,122",
    foodRetailSales: "47.0%",
    treatSales: "801.39",
    treatRetailSales: "18.0%",
    returnAmt: "0.00",
    discountAmt: "0.00",
    promotionTrans: "0",
    promoTrans: "5%",
  },
  {
    salesRep: "Cole jr",
    trans: "60",
    totalUnit: "239",
    upt: "5.66",
    netSales: "4,448",
    atv: "74.14",
    foodSales: "2,122",
    foodRetailSales: "47.0%",
    treatSales: "801.39",
    treatRetailSales: "18.0%",
    returnAmt: "0.00",
    discountAmt: "0.00",
    promotionTrans: "0",
    promoTrans: "5%",
  },
  {
    salesRep: "Mohamed Ismail",
    trans: "60",
    totalUnit: "239",
    upt: "5.66",
    netSales: "4,448",
    atv: "74.14",
    foodSales: "2,122",
    foodRetailSales: "47.0%",
    treatSales: "801.39",
    treatRetailSales: "18.0%",
    returnAmt: "0.00",
    discountAmt: "0.00",
    promotionTrans: "0",
    promoTrans: "5%",
  },
  {
    salesRep: "Kimberly Melendez",
    trans: "60",
    totalUnit: "239",
    upt: "5.66",
    netSales: "4,448",
    atv: "74.14",
    foodSales: "2,122",
    foodRetailSales: "47.0%",
    treatSales: "801.39",
    treatRetailSales: "18.0%",
    returnAmt: "0.00",
    discountAmt: "0.00",
    promotionTrans: "0",
    promoTrans: "5%",
  },
  {
    salesRep: "Kory Brown",
    trans: "60",
    totalUnit: "239",
    upt: "5.66",
    netSales: "4,448",
    atv: "74.14",
    foodSales: "2,122",
    foodRetailSales: "47.0%",
    treatSales: "801.39",
    treatRetailSales: "18.0%",
    returnAmt: "0.00",
    discountAmt: "0.00",
    promotionTrans: "0",
    promoTrans: "5%",
  },
];
