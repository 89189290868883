import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Collapse as MuiCollapse, IconButton } from "@mui/material";

import React, { useState } from "react";

const Collapse = (props) => {
  const { title, children } = props;
  const [expand, setExpand] = useState(false);

  return (
    <>
      <div
        onClick={() => setExpand(!expand)}
        className="mt-50 bg-white br-15 pt-10 pb-10 c-pointer d-flex j-between a-center"
      >
        <div>
          <span className="fw-4 fs-18 gray-color tx-upp ml-10 border-left">
            {title}
          </span>
        </div>
        <div>
          <IconButton
            sx={{
              p: "5px",
              mr: "10px",
            }}
          >
            {expand ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        </div>
      </div>
      <MuiCollapse in={expand}>
        <div className="mt-20">{children}</div>
      </MuiCollapse>
    </>
  );
};

export default Collapse;
