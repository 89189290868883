import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { Alert, Dialog } from "../../../layout";
import { API, action, controller } from "../../../api/api";
import { CartContext } from "./Sell";
import SelectVariantProd from "./SelectVariantProd";
import { no_img } from "../../../assets";

const prodCls = {
  borderTopLeftRadius: "0.8rem",
  borderTopRightRadius: "0.8rem",
  height: "150px",
  width: "100%",
  objectFit: "cover",
  borderBottom: "1px solid var(--grayLight-color)",
};

const Body = () => {
  const [prods, setProds] = useState([]);
  const { selOutlet, setLoader } = useContext(AppContext);
  const { cartData, setCartData } = useContext(CartContext);
  const [selVarProd, setSelVarProd] = useState();
  const [showVarProdForm, setShowVarProdForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });


  useEffect(() => {
    // Get Products
    if (selOutlet.id) {
      setLoader(true);
      API(controller.Product + action.GetAllByOutlet).getById(
        selOutlet.id,
        (res) => {
          res && res.status === 200 && setProds([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  const handleAddToCart = (val) => {
    switch (val.prodType) {
      // Standard Product
      case 1:
      case 3:
        if (
          cartData.sellItems &&
          cartData.sellItems.some(
            (j) => j.prodId === val.id && j.prodType === val.prodType
          )
        ) {
          setCartData((j) => ({
            ...j,
            sellItems: j.sellItems.map((x) => {
              const _price = (parseFloat(x.qty) + 1) * x.price;
              const _totalPrice =
                _price - _price * (parseFloat(x.discount || 0) / 100);

              return {
                ...(x.prodId === val.id && x.prodType === val.prodType
                  ? {
                      ...x,
                      qty: parseFloat(x.qty) + 1,
                      totalPrice: _totalPrice,
                    }
                  : x),
              };
            }),
          }));
        } else {
          setCartData((j) => ({
            ...j,
            sellItems: [
              {
                prodType: val.prodType,
                prodId: val.id,
                prod: val,
                qty: 1,
                price: val.rtlPrice,
                totalPrice: val.rtlPrice,
                discount: "",
                note: "",
              },
              ...j.sellItems,
            ],
          }));
        }

        break;
      // Variant Product
      case 2:
        handleShowVariants(val);
        break;
      default:
        break;
    }
  };

  const handleSelVarProd = (selProd, handleClose) => {
    var _selectedProd = cartData.sellItems.find(
      (j) => j.variantId === selProd.variantId
    );
    if (_selectedProd) {
      setCartData((j) => ({
        ...j,
        sellItems: j.sellItems.filter(x => x.prodType === 2).map((x) => {
          const _price = (parseFloat(x.qty) + 1) * x.price;
          const _totalPrice =
            _price - _price * (parseFloat(x.discount || 0) / 100);
          return {
            ...(x.prodId === selProd.prodId && x.variantId === selProd.variantId
              ? {
                  ...x,
                  qty: parseFloat(x.qty) + 1,
                  totalPrice: _totalPrice,
                }
              : x),
          };
        }),
      }));
    } else if(selProd.prod && selProd.variant)
      setCartData((j) => {
        return {
          ...j,
          sellItems: [
            {
              prodType: selProd.prodType,
              prodId: selProd.prodId,
              prod: selProd.prod,
              variantId: selProd.variantId,
              variant: selProd.variant,
              qty: 1,
              price: selProd.prod.rtlPrice,
              totalPrice: selProd.prod.rtlPrice,
              discount: "",
              note: "",
            },
            ...j.sellItems,
          ],
        };
      });

    handleClose();
  };

  const handleShowVariants = (val) => {
    setSelVarProd(val);
    setShowVarProdForm(true);
  };

  return (
    <>
      <Box
        sx={{
          height: {
            sm: "calc(100vh - 305px)",
            xl: "calc(100vh - 300px)",
          },
          overflow: "auto",
          borderRadius: "0.8rem",
          padding: "10px 10px 10px 8px",
        }}
      >
        <Grid container spacing={2}>
          {prods.map((prod, i) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={i}
              onClick={() => handleAddToCart(prod)}
            >
              <Paper className="br-8 c-pointer bg-white h-100pr">
                <div className="p-5">
                  <img
                    style={prodCls}
                    className="img-fluid obj-contain"
                    src={prod.imgs.length > 0 ? prod.imgs[0].imgUrl : no_img}
                    alt="prod-img"
                  />
                </div>
                <Divider className="bg-grayXS" />
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "13px" }}
                  className="p-5 fw-5 gray-color"
                >
                  {prod.name}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Variant Product Form */}
      {selVarProd && (
        <Dialog show={showVarProdForm} onClose={false} maxWidth="sm">
          <SelectVariantProd
            {...{
              setShowForm: setShowVarProdForm,
              prod: selVarProd,
              handleSelVarProd,
              setAlert,
            }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Body;
