import { ListItemIcon, MenuItem, Paper } from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../controls";
import { useNavigate } from "react-router-dom";
import {
  BlockRounded,
  CurrencyExchangeRounded,
  DesignServicesRounded,
  FormatQuoteRounded,
  HomeRounded,
  KeyboardReturnRounded,
  MoreHorizRounded,
  UpdateRounded,
} from "@mui/icons-material";
import { Alert, Dialog, MenuPopup, Popover } from "../../../layout";
import RetrieveSales from "./RetrieveSales";
import RtvSellConf from "./RtvSellConf";
import ParkSale from "./ParkSale";
import QuoteForm from "./QuoteForm";
import ServiceForm from "./ServiceForm";

const Actions = () => {
  const navigate = useNavigate();
  const [moreEl, setMoreEl] = useState(null);
  const [retrieveEl, setRetrieveEl] = useState(null);
  const [showRtvSellConf, setShowRtvSellConf] = useState(false);
  const [showParkSaleForm, setShowParkSaleForm] = useState(false);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [showServiceForm, setShowServiceForm] = useState(false);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleMoreAct = (e) => {
    setMoreEl(e.currentTarget);
  };

  const handleRtvSellLi = (e) => {
    setRetrieveEl(e.currentTarget);
  };

  const handleRtvSell = (data) => {
    setRetrieveEl(null);
    setShowRtvSellConf(true);
  };

  const handleParkSale = () => {
    setShowParkSaleForm(true);
  };

  const handleQuoteForm = () => {
    setShowQuoteForm(true);
  };

  const handleServiceForm = () => {
    setShowServiceForm(true);
  };

  const handleRefund = () => {
    navigate("refund");
  };

  const handleDiscardSale = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to discard this sale?",
      onConfirm: () => {
        setAlert({
          isOpen: true,
          type: "success",
          title: "Discard Successfully",
        });
      },
    });
  };

  return (
    <>
      <Paper
        className="br-8 p-10 d-flex bg-white g-10 mt-10"
        sx={{
          minHeight: "80px",
          flexWrap: { xs: "wrap", md: "inherit" },
        }}
      >
        <Button
          onClick={() => navigate("/sell")}
          variant="text"
          fullWidth
          startIcon={<HomeRounded className="fs-26" />}
          sx={{ display: "block", "& .MuiButton-startIcon": { mr: 0 } }}
        >
          <span className="fs-12">Home</span>
        </Button>
        <Button
          onClick={handleRtvSellLi}
          variant="text"
          aria-describedby="retrieve_sale"
          fullWidth
          sx={{ display: "block", "& .MuiButton-startIcon": { mr: 0 } }}
          startIcon={<KeyboardReturnRounded className="fs-26" />}
        >
          <span className="fs-12">Retrieve Sale</span>
        </Button>
        <Button
          onClick={handleParkSale}
          variant="text"
          fullWidth
          sx={{ display: "block", "& .MuiButton-startIcon": { mr: 0 } }}
          startIcon={<UpdateRounded className="fs-26" />}
        >
          <span className="fs-12">Park Sale</span>
        </Button>
        <Button
          onClick={handleRefund}
          variant="text"
          fullWidth
          sx={{ display: "block", "& .MuiButton-startIcon": { mr: 0 } }}
          startIcon={<CurrencyExchangeRounded className="fs-26" />}
        >
          <span className="fs-12">Refund</span>
        </Button>
        <>
          <Button
            aria-controls="more_actions"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleMoreAct}
            variant="text"
            fullWidth
            sx={{ display: "block", "& .MuiButton-startIcon": { mr: 0 } }}
            startIcon={<MoreHorizRounded className="fs-26" />}
          >
            <span className="fs-12">More Actions</span>
          </Button>
          <MenuPopup
            anchorEl={moreEl}
            setAnchorEl={setMoreEl}
            id="more_actions"
            transformOrigin={{ horizontal: "center", vertical: "bottom" }}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <MenuItem onClick={handleQuoteForm}>
              <ListItemIcon>
                <FormatQuoteRounded fontSize="small" />
              </ListItemIcon>
              Create Quote
            </MenuItem>
            <MenuItem onClick={handleServiceForm}>
              <ListItemIcon>
                <DesignServicesRounded fontSize="small" />
              </ListItemIcon>
              Create Service
            </MenuItem>
            <MenuItem onClick={handleDiscardSale}>
              <ListItemIcon>
                <BlockRounded fontSize="small" />
              </ListItemIcon>
              Discard Sale
            </MenuItem>
          </MenuPopup>
        </>
      </Paper>

      {/* Retrieve Sell  */}
      <Popover
        id="retrieve_sale"
        anchorEl={retrieveEl}
        setAnchorEl={setRetrieveEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <RetrieveSales {...{ handleRtvSell }} />
      </Popover>

      {/* Retrieve Sell Confirmation */}
      <Dialog show={showRtvSellConf} onClose={false} maxWidth="sm">
        <RtvSellConf {...{ setShowForm: setShowRtvSellConf }} />
      </Dialog>

      {/* Park Sale Form */}
      <Dialog show={showParkSaleForm} onClose={false} maxWidth="sm">
        <ParkSale {...{ setShowForm: setShowParkSaleForm }} />
      </Dialog>

      {/* Create Quote Form */}
      <Dialog show={showQuoteForm} onClose={false} maxWidth="md">
        <QuoteForm {...{ setShowForm: setShowQuoteForm }} />
      </Dialog>

      {/* Create Service Form */}
      <Dialog show={showServiceForm} onClose={false} maxWidth="md">
        <ServiceForm {...{ setShowForm: setShowServiceForm }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Actions;
