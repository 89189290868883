import React, { useState } from "react";
import { Form } from "../../../layout";
import { Divider, Grid, IconButton, Paper } from "@mui/material";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputMultFile,
} from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";

const ReturnStockForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading] = useState(false);
  const { ReturnStockMdl } = Model();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    handleInpDate,
  } = UseForm(ReturnStockMdl);

  const validateForm = () => {
    let temp = {};
    temp.returnNo = values.returnNo !== "" ? "" : "Return noumber is required.";
    temp.deliveryTo =
      values.deliveryTo !== "" ? "" : "Deliver outlet is required.";
    temp.returnForm =
      values.returnForm !== "" ? "" : "Return from is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
    }
  };
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Paper>
        <div className="d-flex j-between a-center p-10">
          <div className="d-flex a-center g-10">
            <div>
              <IconButton size="small" onClick={() => navigate(-1)}>
                <ArrowBackRounded />
              </IconButton>
            </div>

            <div>
              <h1 className="form-title fw-4 gray-color tx-upp">
                Return Stock
              </h1>
            </div>
          </div>

          <div>
            <Button type="submit" loading={loading}>
              {id ? "Save Changes" : "Submit"}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="p-15 mt-15">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Input
                label="Return No."
                name="returnNo"
                required={true}
                error={errors.returnNo}
                value={values.returnNo}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputAuto
                label="Deliver To"
                name="deliveryTo"
                required={true}
                value={values.deliveryTo}
                error={errors.deliveryTo}
                onChange={handleInpChg}
                options={[
                  { id: 1, label: "Outlet - 1" },
                  { id: 2, label: "Outlet - 2" },
                  { id: 3, label: "Outlet - 3" },
                  { id: 4, label: "Outlet - 4" },
                  { id: 5, label: "Outlet - 5" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputAuto
                label="Return Form"
                name="returnForm"
                required={true}
                value={values.returnForm}
                error={errors.returnForm}
                onChange={handleInpChg}
                options={[
                  { id: 1, label: "Outlet - 1" },
                  { id: 2, label: "Outlet - 2" },
                  { id: 3, label: "Outlet - 3" },
                  { id: 4, label: "Outlet - 4" },
                  { id: 5, label: "Outlet - 5" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Supplier Invoice"
                name="splrInv"
                value={values.splrInv}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label="Delivery Due"
                name="deliveryDue"
                value={values.deliveryDue}
                onChange={(date) =>
                  handleInpDate(
                    "deliveryDue",
                    new Date(date).toLocaleDateString()
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Name / Reference"
                name="nameOrRefe"
                value={values.nameOrRefe}
                onChange={handleInpChg}
              />
            </Grid>

            <Grid item xs={12} md={4} className="d-flex w-100pr a-center">
              <div className="w-100pr">
                <InputMultFile
                  label="Upload Invoice"
                  name="files"
                  maxSize={3}
                  values={values.files}
                  onChange={handleFile}
                  rmv={(file) => {
                    setValues((x) => ({
                      ...x,
                      files: x.files.filter(
                        (j) => j.lastModified !== file.lastModified
                      ),
                      returnFiles: x.returnFiles.filter(
                        (j) => j.id !== file.id
                      ),
                    }));
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Form>
  );
};

export default ReturnStockForm;
