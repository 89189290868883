import React, { useState } from "react";
import { Form } from "../../../layout";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Button, Input, InputAuto, InputDate } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackRounded, DeleteForeverRounded } from "@mui/icons-material";
import { dog_cloth } from "../../../assets";

const SpecialOrderForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading] = useState(false);
  const { SpecialOrderMdl } = Model();
  const [searchVal, setSearchVal] = useState("");
  const { values, errors, setErrors, handleInpChg, handleInpDate } =
    UseForm(SpecialOrderMdl);

  const validateForm = () => {
    let temp = {};
    temp.cstmrId = values.cstmrId !== "" ? "" : "Customer is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.email = values.email !== "" ? "" : "Email is required.";
    temp.deliveryDate = values.deliveryDate !== "" ? "" : "Date is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleProdSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
    }
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Paper className="shadow-sm">
        <div className="d-flex j-between a-center p-10">
          <div className="d-flex a-center g-10">
            <div>
              <IconButton size="small" onClick={() => navigate(-1)}>
                <ArrowBackRounded />
              </IconButton>
            </div>

            <div>
              <h1 className="form-title fw-4 gray-color tx-upp">
                Special Order
              </h1>
            </div>
          </div>

          <div>
            <Button type="submit" loading={loading}>
              {id ? "Save Changes" : "Submit"}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="p-15 mt-15">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3.5}>
              <InputAuto
                label="Customer"
                name="cstmrId"
                required={true}
                value={values.cstmrId}
                error={errors.cstmrId}
                onChange={handleInpChg}
                options={[
                  { id: 1, label: "Customer - 1" },
                  { id: 2, label: "Customer - 2" },
                  { id: 3, label: "Customer - 3" },
                  { id: 4, label: "Customer - 4" },
                  { id: 5, label: "Customer - 5" },
                ]}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Input
                label="Phone"
                name="phone"
                required={true}
                error={errors.phone}
                value={values.phone}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label="Email"
                name="email"
                required={true}
                error={errors.email}
                value={values.email}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={2.5}>
              <InputDate
                label="Delivery Date"
                name="deliveryDate"
                required={true}
                error={errors.deliveryDate}
                value={values.deliveryDate}
                onChange={(date) =>
                  handleInpDate(
                    "deliveryDate",
                    new Date(date).toLocaleDateString()
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Note"
                name="note"
                multiline
                rows={3}
                value={values.note}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex a-center pl-5 mt-20">
                <span
                  className="gray-color tx-upp fw-5"
                  style={{ minWidth: "100px" }}
                >
                  Products
                </span>
                <div className="bs-secondary w-100pr" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <InputAuto
                label="Search or scan to add a product"
                value={searchVal}
                onChange={handleProdSearch}
                options={[
                  { id: 1, label: "Product 1" },
                  { id: 2, label: "Product 2" },
                  { id: 3, label: "Product 3" },
                  { id: 4, label: "Product 4" },
                  { id: 5, label: "Product 5" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer className="bs-secondary br-15 bg-inherit">
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>
                        <span className="m-0 p-0">Current Inventory</span>
                        <span className="gray-color fs-12">
                          &nbsp;(All Outlets)
                        </span>
                      </TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Cost Price</TableCell>
                      <TableCell>Total Cost</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prods.map((prod, i) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{ "& > *": { borderBottom: "unset" } }}
                      >
                        <TableCell>
                          <div className="d-flex a-center g-10">
                            <img
                              src={prod.img}
                              alt="product_img"
                              className="img-fluid w-40 bg-white br-10 shadow-xs"
                            />
                            <div className="mr-20 d-grid ml-10">
                              <span className="fs-16">{prod.name}</span>
                              <span className="fs-12 gray-color">
                                {prod.skuCode}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span className="fs-16">{prod.currentInv}</span>
                        </TableCell>
                        <TableCell>
                          <Input
                            className="shadow-xs"
                            variant="outlined"
                            size="small"
                            placeholder="$ 0.00"
                            sx={{ maxWidth: "200px" }}
                            value={prod.qty}
                            inputProps={{ maxLength: 200 }}
                          />
                        </TableCell>

                        <TableCell>
                          <Input
                            className="shadow-xs"
                            variant="outlined"
                            placeholder="$ 0.00"
                            size="small"
                            sx={{ maxWidth: "200px" }}
                            value={prod.qty}
                            inputProps={{ maxLength: 200 }}
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            className="fs-18 gray-color"
                            style={{ minWidth: "200px" }}
                          >
                            $&nbsp;{prod.totalPrice}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Delete this variants">
                            <IconButton color="error" size="small">
                              <DeleteForeverRounded className="fs-28" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell className="fs-16 fw-6 gray-color tx-upp">
                        Total Cost:
                      </TableCell>
                      <TableCell className="fs-18 fw-6 gray-color tx-upp">
                        $0.00
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Form>
  );
};

export default SpecialOrderForm;

const prods = [
  {
    id: 1,
    img: dog_cloth,
    name: "Product Name - 1",
    skuCode: "1245345",
    currentInv: "10",
    qty: "",
    price: "",
    totalPrice: "0.00",
  },
  {
    id: 2,
    img: dog_cloth,
    name: "Product Name - 2",
    skuCode: "1245345",
    currentInv: "10",
    qty: "",
    price: "",
    totalPrice: "0.00",
  },
  {
    id: 3,
    img: dog_cloth,
    name: "Product Name - 3",
    skuCode: "1245345",
    currentInv: "10",
    qty: "",
    price: "",
    totalPrice: "0.00",
  },
  {
    id: 4,
    img: dog_cloth,
    name: "Product Name - 4",
    skuCode: "1245345",
    currentInv: "10",
    qty: "",
    price: "",
    totalPrice: "0.00",
  },
];
