import { Paper } from "@mui/material";
import React from "react";
import { Button } from "../../../controls";
import ReactApexChart from "react-apexcharts";
import { getCurrentWeek } from "../../../hooks/Method";
import { useNavigate } from "react-router-dom";

const Widget = (props) => {
  const { sales, title, total, url } = props;
  const navigate = useNavigate();
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: getCurrentWeek(),
    },
    yaxis: {
      title: {
        // text: "Total Sales (USD)",
        style: {
          color: "var(--gray-color)",
          fontFamily: "roboto",
        },
      },
      labels: {
        formatter: (val) => "$" + val,
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#7a4988"],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      y: {
        formatter: (val) => "$" + val,
      },
    },
  };
  const series = [
    {
      name: "",
      data: sales,
    },
  ];

  const handleViewReport = () => {
    navigate(`${url || ""}`);
  };

  return (
    <Paper className="p-15 shadow-xs">
      <div className="d-flex j-between a-center mb-30">
        <div>
          <span className="fw-5 tx-upp fs-20 gray-color border-left">
            {title}
          </span>
        </div>
        <div>
          <Button onClick={handleViewReport} variant="text">
            View Report
          </Button>
        </div>
      </div>
      <div className="d-flex g-10 mb-30 a-center ml-20">
        <span className="fs-40">{total}</span>
        <div className="d-grid gray-color">
          <span>No Change</span>
          <span className="fs-12">Previous Day</span>
        </div>
      </div>
      <div>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          width="100%"
          height="220"
        />
      </div>
    </Paper>
  );
};

export default Widget;
