import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { prod1, prod2, prod3, prod4, prod5 } from "../../../assets";
import { ReplyRounded } from "@mui/icons-material";

const RetrieveSales = (props) => {
  const { handleRtvSell } = props;

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Parked Sale</TableCell>
              <TableCell align="right">Customer</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prods.map((prod, i) => (
              <TableRow
                key={i}
                hover
                onClick={() => handleRtvSell(prod)}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <div className="d-flex g-15">
                    <div>
                      <img
                        src={prod.img}
                        className="br-4 h-60 w-60 obj-cover"
                        alt="product_img"
                      />
                    </div>
                    <div className="d-grid g-5">
                      <span>Product 1</span>
                      <span>Product 2</span>
                      <span className="fs-12 gray-color">
                        Parked 2 days ago by Jihad Hassan
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="right">Jihad Hassan</TableCell>
                <TableCell align="right">
                  <div className="d-flex flex-reverse">
                    <IconButton color="primary">
                      <ReplyRounded />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RetrieveSales;

const prods = [
  { id: 1, img: prod1, name: "Product Name", qty: 20, price: 50 },
  { id: 2, img: prod2, name: "Product Name", qty: 20, price: 50 },
  { id: 3, img: prod3, name: "Product Name", qty: 20, price: 50 },
  { id: 4, img: prod4, name: "Product Name", qty: 20, price: 50 },
  { id: 5, img: prod5, name: "Product Name", qty: 20, price: 50 },
];
