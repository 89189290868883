import React from "react";
import { Avatar, Divider, IconButton, Tooltip } from "@mui/material";
import { EditRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const CustomerInfo = (props) => {
  const { cstmr, gender, accBalType } = props;
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/customer_form/${cstmr.id}`);
  };

  return (
    <>
      {cstmr ? (
        <div className="d-grid bg-white p-10 br-25 gray-color">
          <div className="tx-right">
            <Tooltip title="Edit Member">
              <IconButton
                onClick={handleEdit}
                size="small"
                color="primary"
                sx={{ border: "1px solid var(--primarySM-color)" }}
              >
                <EditRounded className="fs-20 p-2" />
              </IconButton>
            </Tooltip>
          </div>
          <div className="d-flex j-center mb-10">
            <Avatar
              src={cstmr.photo}
              alt="profile_pic"
              className="obj-cover"
              sx={{ width: "110px", height: "110px" }}
            />
          </div>
          <div className="d-grid gap-10 j-center">
            <div className="tx-center">
              <p className="fs-28 m-0 mb-5 fw-4">
                {cstmr.firstName + " " + cstmr.lastName}
              </p>
              <p className="fs-12 grayMD-color m-0">
                Customer scince:{" "}
                {new Date(cstmr.createDate).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div className="p-10">
            <div className="d-flex a-center mt-40 mb-10">
              <span
                className="fs-12 fs-italic  tx-upp"
                style={{ width: "180px" }}
              >
                Personal Details
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
            {cstmr.dob && (
              <div className="d-flex flax-wrap j-between a-center mb-5">
                <span className="fs-15 mr-30">Birthday :</span>
                <div className="d-grid tx-center">
                  <span className="fs-15">
                    {moment().diff(moment(cstmr.dob, "MM/DD/YYYY"), "years")}{" "}
                    Years old
                  </span>
                  <span className="fs-12 grayMD-color">
                    {new Date(cstmr.dob).toDateString()}
                  </span>
                </div>
              </div>
            )}
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">Gender :</span>
              <span className="fs-15">{gender}</span>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">Group :</span>
              <span className="fs-15">
                {cstmr.cstmrGroup && cstmr.cstmrGroup.name}
              </span>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">Customer Code :</span>
              <span className="fs-15">{cstmr.cstmrCode}</span>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">Account Balance Type :</span>
              <span className="fs-15">{accBalType}</span>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">Phone :</span>
              <a className="fs-15 link" href={`tel:${cstmr.phone}`}>
                {cstmr.phone}
              </a>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">Email :</span>
              <a className="link fs-15" href={`mailto:${cstmr.email}`}>
                {cstmr.email}
              </a>
            </div>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default CustomerInfo;
