import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../layout";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import {
  ClearRounded,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "../../controls";
import { AppContext } from "../../App";
import { API, action, controller } from "../../api/api";

const Customers = () => {
  const navigate = useNavigate();
  const { selOutlet, setLoader } = useContext(AppContext);
  const [cstmrs, setCstmrs] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    if (selOutlet.id) {
      API(controller.Customer + action.GetAllByOutlet).getById(
        selOutlet.orgId,
        (res) => {
          res && res.status === 200 && setCstmrs([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  const handleAddEdit = (id) => {
    navigate(`/customer_form/${id || ""}`);
  };

  const handleView = (id) => {
    navigate(`/view_customer/${id}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this customer profile?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setCstmrs((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        API(controller.Customer).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="View">
        <IconButton
          onClick={() => handleView(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
          className="mr-5 p-5"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Customer</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "cstmr", label: "Customer" },
          { id: "contact", label: "contact" },
          { id: "credit", label: "Store Credit" },
          { id: "cardGroup", label: "Card / Group" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={cstmrs.map((j) => {
          return {
            ...j,
            cstmr: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <Avatar className="bg-primary" src={j.photo} />
                <div className="d-grid">
                  <span className="">{j.firstName + " " + j.lastName}</span>
                  <span className="fs-12">{j.cstmrCode + " | " + j.phone}</span>
                </div>
              </div>
            ),
            credit: "$0.00",
            contact: (
              <div className="d-grid ">
                {j.email && (
                  <a
                    className="fs-14 primary-color c-pointer mb-3"
                    href={`mailto:${j.email}`}
                  >
                    ✉️ {j.email}
                  </a>
                )}
                <a
                  className="fs-14 primary-color c-pointer"
                  href={`tel:1955206146`}
                >
                  📞 {j.phone}
                </a>
              </div>
            ),
            cardGroup: (
              <div className="d-grid ">
                <span className="fs-14 gray-color c-pointer">
                  Has card:{" "}
                  <span className="fw-5">{j.cardNumber ? "Yes" : "No"}</span>
                </span>
                <span className="fs-12 fw-4 gray-color c-pointer">
                  Group:{" "}
                  <span className="fw-5">
                    {j.cstmrGroup && j.cstmrGroup.name}
                  </span>{" "}
                </span>
              </div>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Customers;
