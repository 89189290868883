import { Grid } from "@mui/material";
import React from "react";
import Widget from "./Widget";

const StoreMetricsWidget = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Widget title="Retail Sales" number="$0.00" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Widget title="RETAIL AT" number="$0.00" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Widget title="RETAIL UPT" number="0.00" />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Widget title="FOOD % RETAIL SALES" number="0.00%" />
      </Grid>
    </Grid>
  );
};

export default StoreMetricsWidget;
