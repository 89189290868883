import React from "react";
import { Divider, Paper } from "@mui/material";

const SalesAvg = () => {
  return (
    <Paper className="shadow-xs bg-white h-100pr d-grid a-center">
      <div className="d-grid gray-color g-10 mb-30 p-20">
        <span className="fs-20 tx-upp">Average sale value</span>
        <span className="fs-28 fw-5">$83.79</span>
        <span className="fs-14">$1.96 more than last Thursday</span>
      </div>
      <Divider />
      <div className="d-grid gray-color g-10 p-20 mt-30">
        <span className="fs-20 tx-upp">Average sale value</span>
        <span className="fs-28 fw-5">3.7</span>
        <span className="fs-14">0.96 more than last Thursday</span>
      </div>
    </Paper>
  );
};

export default SalesAvg;
