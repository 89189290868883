import React from "react";
import { Select } from "../../controls";
import { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const FoodSalesByCat = () => {
  const [fltLocaltion, setFltLocaltion] = useState(1);

  const handleLocation = (e) => {
    setFltLocaltion(e.target.value);
  };

  return (
    <>
      <Paper className="bs-primaryMD">
        <div className="d-flex a-center j-between p-10 mb-10">
          <div>
            <span className="w-100pr fs-18 fw-5 gray-color border-left tx-upp">
              Food Sales By Category
            </span>
          </div>
          <Box
            className="d-flex g-10"
            sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
          >
            <Select
              size="small"
              sx={{ minWidth: "200px" }}
              variant="outlined"
              value={fltLocaltion}
              onChange={handleLocation}
              options={[
                { id: 1, label: "Location 1" },
                { id: 2, label: "Location 2" },
                { id: 3, label: "Location 3" },
                { id: 4, label: "Location 4" },
                { id: 5, label: "Location 5" },
              ]}
            />
          </Box>
        </div>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="primary-color">Food By Category</TableCell>
              <TableCell className="primary-color" align="right">
                Total Amount
              </TableCell>
              <TableCell className="primary-color" align="right">
                Sale
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{item.foodCat}</TableCell>
                <TableCell align="right">$ {item.amt}</TableCell>
                <TableCell align="right">{item.pct}</TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell className="fw-5">Overall Total: </TableCell>
              <TableCell className="fw-5" align="right">
                $0.00
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default FoodSalesByCat;

const records = [
  { foodCat: "Wet", amt: "604.21", pct: "10%" },
  { foodCat: "Toppers", amt: "604.21", pct: "10%" },
  { foodCat: "Row Alternative", amt: "604.21", pct: "10%" },
  { foodCat: "Kibble", amt: "604.21", pct: "10%" },
  { foodCat: "Frozen Row", amt: "604.21", pct: "10%" },
];
