import React, { useState } from "react";
import { Alert, Table } from "../../../layout";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { VisibilityRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../controls";

const SplrReturns = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleAddEdit = (id) => {
    navigate(`/dashboard/supplier_return_form/${id || ""}`);
  };

  const handleView = (id) => {
    navigate(`/dashboard/supplier_return_form/${id}`);
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="View">
        <IconButton
          onClick={() => handleView(val.id)}
          size="small"
          color="primary"
          className="p-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Return</Button>
    </div>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "splr", label: "Suppler" },
          { id: "contact", label: "Contact" },
          { id: "note", label: "Note" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={customers.map((j) => {
          return {
            ...j,
            splr: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <Avatar className="bg-primary">JH</Avatar>
                <div className="d-grid">
                  <span className="">{j.firstName + " " + j.lastName}</span>
                  <span className="fs-12">{j.code}</span>
                </div>
              </div>
            ),
            contact: (
              <div className="d-grid g-5">
                <a className="fs-14 link" href="tel:+44-785-7895">
                  +44-785-7895
                </a>
                <a className="fs-14 link" href="mailto:xyz@abc.com">
                  xyz@abc.com
                </a>
              </div>
            ),
            note: (
              <div
                style={{
                  maxWidth: "300px",
                  whiteSpace: "normal",
                  fontSize: "14px",
                }}
              >
                <span>{j.note}</span>
              </div>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default SplrReturns;

const customers = [
  {
    id: 1,
    date: "01/02/2023",
    firstName: "Jihad",
    lastName: "Hassan",
    code: "Jihad-WW73",
    mobile: "01955206146",
    email: "example@email.com",
    note: "7943 E. Pilgrim Drive Mishawaka, IN 46544",
  },
  {
    id: 1,
    status: 1,
    date: "01/02/2023",
    firstName: "Jihad",
    lastName: "Hassan",
    code: "Jihad-WW73",
    mobile: "01955206146",
    email: "example@email.com",
    note: "7943 E. Pilgrim Drive Mishawaka, IN 46544",
  },
  {
    id: 1,
    date: "01/02/2023",
    firstName: "Jihad",
    lastName: "Hassan",
    code: "Jihad-WW73",
    mobile: "01955206146",
    email: "example@email.com",
    note: "7943 E. Pilgrim Drive Mishawaka, IN 46544",
  },
  {
    id: 1,
    date: "01/02/2023",
    firstName: "Jihad",
    lastName: "Hassan",
    code: "Jihad-WW73",
    mobile: "01955206146",
    email: "example@email.com",
    note: "7943 E. Pilgrim Drive Mishawaka, IN 46544",
  },
  {
    id: 1,
    status: 1,
    date: "01/02/2023",
    firstName: "Jihad",
    lastName: "Hassan",
    code: "Jihad-WW73",
    mobile: "01955206146",
    email: "example@email.com",
    note: "7943 E. Pilgrim Drive Mishawaka, IN 46544",
  },
  {
    id: 1,
    date: "01/02/2023",
    firstName: "Jihad",
    lastName: "Hassan",
    code: "Jihad-WW73",
    mobile: "01955206146",
    email: "example@email.com",
    note: "7943 E. Pilgrim Drive Mishawaka, IN 46544",
  },
];
