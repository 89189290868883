import { Popover as MuiPopover } from "@mui/material";
import * as React from "react";

export default function Popover(props) {
  const {
    children,
    anchorEl,
    setAnchorEl,
    className = "",
    id,
    ...other
  } = props;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={`${className} shadow-lg`}
        PaperProps={{
          className: `${className} shadow-md bg-white`,
          sx: {
            maxHeight: "500px",
          },
        }}
        {...other}
      >
        {children}
      </MuiPopover>
    </div>
  );
}
