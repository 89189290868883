import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "../../../layout";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useNavigate } from "react-router-dom";

const SendFrgtCode = (props) => {
  const { forgotVal, setForgotVal, setActiveStep } = props;
  const { ForgotMdl } = Model();
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(ForgotMdl);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let temp = {};
    temp.email = values.email !== "" ? "" : "Email is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setActiveStep(1);
      setLoading(false);
      values.forgotStep = 1;
    }
  };

  useEffect(() => {
    setValues(forgotVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setForgotVal({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Form noValidate onSubmit={submitForm}>
      <Grid container className="p-15 pt-0" spacing={3}>
        <Grid item xs={12} className="tx-center mt-20 pb-30">
          <h2 className="fs-22 fw-4 tx-upp h gray-color m-0 p-5">
            Forgot Password
          </h2>
          <div className="pos-relative d-flex j-center mt-5">
            <div className="border-bottom w-60" />
          </div>
        </Grid>
        <Grid item xs={12} className="mb-10">
          <Input
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInpChg}
            error={errors.email}
            className="input-primary w-100pr"
          />
        </Grid>
        <Grid item xs={12} className="text-center">
          <Button
            loading={loading}
            type="submit"
            size="large"
            className="w-100pr mb-10"
          >
            Send Verification Code
          </Button>
          <Button
            onClick={() => navigate("/auth")}
            variant="text"
            className="w-100pr"
            size="large"
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SendFrgtCode;
