import React, { useState } from "react";
import { Button, Input, Select } from "../../../controls";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { ArrowBackRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const RefundForm = (props) => {
  const navigate = useNavigate();
  const { ProdRetType } = DropLi();
  const { RefundMdl } = Model();
  const [loading, setloading] = useState(false);
  const { values, errors, setErrors, handleInpChg } = UseForm(RefundMdl);

  const validateForm = () => {
    let temp = {};
    temp.invoiceId = values.invoiceId !== "" ? "" : "Invoice is required.";
    temp.prodCond =
      values.prodCond !== "" ? "" : "Product condition is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setloading(false);
    }
  };

  return (
    <>
      {/* Display */}
      <Form onSubmit={handleSubmit} noValidate>
        <Paper className="shadow-xs bg-white">
          <div className="d-flex j-between a-center p-10 mb-20">
            <div className="d-flex a-center g-10">
              <div>
                <IconButton size="small" onClick={() => navigate(-1)}>
                  <ArrowBackRounded />
                </IconButton>
              </div>

              <div>
                <h1 className="form-title fw-4 gray-color tx-upp">Refund</h1>
              </div>
            </div>

            <div>
              <Button type="submit" size="large" loading={loading}>
                Submit
              </Button>
            </div>
          </div>
          <Grid container spacing={2} className="p-15">
            <Grid item xs={12} md={8}>
              <div className="gray-color">
                <Input
                  required={true}
                  name="invoiceId"
                  value={values.invoiceId}
                  error={errors.invoiceId}
                  onChange={handleInpChg}
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography className="fw-6">Invoice ID:</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label="Product Condition"
                required={true}
                name="prodCond"
                variant="outlined"
                value={values.prodCond}
                error={errors.prodCond}
                onChange={handleInpChg}
                options={ProdRetType}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Note"
                name="note"
                multiline
                rows={3}
                variant="outlined"
                value={values.note}
                onChange={handleInpChg}
              />
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

export default RefundForm;
