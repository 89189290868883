import React from "react";
import { Table } from "../../../layout";
import { Input } from "../../../controls";

const Payments = () => {
  return (
    <div className="bg-white br-15 shadow-xs">
      <div className="pt-15 pl-15 pr-15">
        <span className="tx-upp fs-18 fw-4 gray-color border-left">
          Payments Summary
        </span>
      </div>
      <div>
        <Table
          showSearch={false}
          ActionComp={() => <></>}
          HeaderComp={() => <></>}
          tblHead={[
            { id: "payType", label: "Payment Types	" },
            { id: "expected", label: "Expected ($)" },
            { id: "counted", label: "Counted ($)" },
            { id: "diff", label: "Differences ($)" },
          ]}
          tblData={records.map((item) => {
            return {
              ...item,
              counted: (
                <Input
                  variant="outlined"
                  size="small"
                  sx={{ maxWidth: "150px" }}
                  placeholder="Enter Amount"
                />
              ),
              diff: "0.00",
            };
          })}
        />
      </div>
    </div>
  );
};

const records = [
  { id: 1, payType: "Other Payment Method", expected: "0.00", counted: "0.00" },
  { id: 1, payType: "PayPal Here", expected: "0.00", counted: "0.00" },
  { id: 1, payType: "Store Credit", expected: "0.00", counted: "0.00" },
  { id: 1, payType: "Other Payment Method", expected: "0.00", counted: "0.00" },
  { id: 1, payType: "PayPal Here", expected: "0.00", counted: "0.00" },
  { id: 1, payType: "Store Credit", expected: "0.00", counted: "0.00" },
];

export default Payments;
