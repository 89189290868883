import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import { InputAuto, InputDate, Select } from "../../../controls";
import DropLi from "../../../hooks/DropLi";
import { subDays } from "date-fns";
import moment from "moment";

const RetailFilter = () => {
  const { PeriodicType } = DropLi();
  const [periodVal, setPeriodVal] = useState("");
  const [outletVal, setOutletVal] = useState("");
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/1/${new Date().getFullYear()}`,
  });

  const handlePeriod = (e) => {
    setPeriodVal(e.target.value);
  };
  const handleDate = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };
  const handleOutlet = (e) => {
    setOutletVal(e.target.value);
  };

  return (
    <>
      <Paper className="p-10 mb-15 shadow-xs">
        <Box className="g-15" sx={{ display: { lg: "flex", xs: "grid" } }}>
          <Select
            label="View"
            value={periodVal}
            variant="outlined"
            fullWidth
            onChange={handlePeriod}
            options={PeriodicType}
          />
          <InputAuto
            label="Outlet"
            variant="outlined"
            value={outletVal}
            fullWidth
            onChange={handleOutlet}
            options={[
              { id: 1, label: "Outlet 1" },
              { id: 2, label: "Outlet 2" },
              { id: 3, label: "Outlet 3" },
              { id: 4, label: "Outlet 4" },
              { id: 5, label: "Outlet 5" },
            ]}
          />
          <Box
            className="d-flex a-center w-100pr"
            sx={{ ml: { xs: 0, md: "15px" } }}
          >
            <InputDate
              variant="outlined"
              fullWidth
              name="fromDate"
              value={sortDate.fromDate}
              views={["day", "month", "year"]}
              openTo="day"
              minDate={new Date("01/01/2000")}
              maxDate={subDays(new Date(sortDate.toDate), 1)}
              onChange={(date) =>
                handleDate("fromDate", new Date(date).toLocaleDateString())
              }
            />
            <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
            <InputDate
              variant="outlined"
              fullWidth
              name="toDate"
              value={sortDate.toDate}
              minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
              views={["day", "month", "year"]}
              openTo="day"
              onChange={(date) =>
                handleDate("toDate", new Date(date).toLocaleDateString())
              }
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default RetailFilter;
