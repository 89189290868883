import {
  BlockRounded,
  ClearRounded,
  LocalPrintshopRounded,
  ReplyRounded,
} from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { Table } from "../../../layout";
import { Button } from "../../../controls";
import { prod1 } from "../../../assets";

const ViewSale = (props) => {
  const { setShowForm, handleViewDtl } = props;

  const handleClose = () => {
    setShowForm(false);
  };

  const HeaderComp = () => (
    <div className="d-flex g-10 flex-wrap mr-10">
      <Button variant="text" startIcon={<ReplyRounded />}>
        Continue Sale
      </Button>
      <Button variant="text" startIcon={<LocalPrintshopRounded />}>
        Print Receipt
      </Button>
      <Button variant="text" startIcon={<BlockRounded />}>
        Void
      </Button>
    </div>
  );

  return (
    <div className="p-10">
      <div className="mb-15 d-flex j-between a-center">
        <div>
          <span className="fs-20 mb-5 fw-5 gray-color tx-upp border-left">
            Sale details
          </span>
        </div>

        <div>
          <IconButton onClick={handleClose} size="small" className="mr-5">
            <ClearRounded className="fs-20 m-2" />
          </IconButton>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            ActionComp={() => <></>}
            HeaderComp={HeaderComp}
            tblHead={[
              { id: "prod", label: "Product" },
              { id: "price", label: "Price" },
              { id: "total", label: "Total" },
            ]}
            tblData={records.map((item) => {
              return {
                ...item,
                prod: (
                  <div className="d-grid" onClick={() => handleViewDtl(item)}>
                    <span className="fs-16 link">{item.name}</span>
                    <span className="fs-12 gray-color">{item.soldBy}</span>
                  </div>
                ),
                price: item.price + " + " + item.tax,
                total: item.price,
              };
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="d-grid g-10">
            <span className="fs-12 fw-5 gray-color">Note: </span>
            <span>Here will be your note...</span>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiTable size="small">
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <div className="d-grid">
                    <span>Subtotal</span>
                    <span>Total tax (No Tax)</span>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className="d-grid">
                    <span>$318.70</span>
                    <span>$0.00</span>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Total Sale</TableCell>
                <TableCell align="right">$318.70</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Balance</TableCell>
                <TableCell align="right">$318.70</TableCell>
              </TableRow>
            </TableBody>
          </MuiTable>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewSale;

const records = [
  {
    id: 1,
    img: prod1,
    name: "Audhild Tee / 10 / Black and White Stripe",
    soldBy: "Jihad Hassan",
    price: "$69.90",
    tax: "0.00 Tax(No Tax)",
  },
  {
    id: 1,
    img: prod1,
    name: "Audhild Tee / 10 / Black and White Stripe",
    soldBy: "Jihad Hassan",
    price: "$69.90",
    tax: "0.00 Tax(No Tax)",
  },
  {
    id: 1,
    img: prod1,
    name: "Audhild Tee / 10 / Black and White Stripe",
    soldBy: "Jihad Hassan",
    price: "$69.90",
    tax: "0.00 Tax(No Tax)",
  },
  {
    id: 1,
    img: prod1,
    name: "Audhild Tee / 10 / Black and White Stripe",
    soldBy: "Jihad Hassan",
    price: "$69.90",
    tax: "0.00 Tax(No Tax)",
  },
];
