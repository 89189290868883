import { Box, Paper, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const TopSellingProd = () => {
  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: prods.map((j) => j.prod),
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - $" + opts.w.globals.series[opts.seriesIndex];
      },
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontSize: "15px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  return (
    <Paper className="shadow-xs p-15 bg-white">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-20">
        Top Selling Product ({new Date().getFullYear()})
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={prods.map((j) => j.amt)}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

const prods = [
  { prod: "Product 1", amt: 300 },
  { prod: "Product 2", amt: 200 },
  { prod: "Product 3", amt: 100 },
  { prod: "Product 4", amt: 250 },
  { prod: "Product 5", amt: 150 },
];

export default TopSellingProd;
