import { Box, Paper, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const TopCustomer = () => {
  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: prods.map((j) => j.prod),
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - $" + opts.w.globals.series[opts.seriesIndex];
      },
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontSize: "15px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  return (
    <Paper className="shadow-xs p-15 bg-white">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-20">
        Top 5 Customer ({new Date().toLocaleString("en-us", { month: "long" })})
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={prods.map((j) => j.amt)}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

const prods = [
  { prod: "Customer 1", amt: 1000 },
  { prod: "Customer 2", amt: 500 },
  { prod: "Customer 3", amt: 600 },
  { prod: "Customer 4", amt: 250 },
  { prod: "Customer 5", amt: 750 },
];

export default TopCustomer;
