import React, { useContext, useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Button, Input, InputAuto, InputDate } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackRounded, ClearRounded } from "@mui/icons-material";
import { no_img } from "../../../assets";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import {
  BGNestedObjToFromData,
  MaxLength,
  RmvEmptyProp,
  ToFormData,
} from "../../../hooks/Method";
import { Alert } from "../../../layout";

const OrderAndReceiveForm = (props) => {
  const { isReceiveForm = false } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { StockMdl } = Model();
  const [searchVal, setSearchVal] = useState("");
  const { outlets, selOutlet, authUser } = useContext(AppContext);
  const [splrs, setSplrs] = useState([]);
  const [searchLi, setSearchLi] = useState([]);
  const [findErr, setFindErr] = useState("");
  const { values, setValues, errors, setErrors, handleInpChg, handleInpDate } =
    UseForm(StockMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setValues((j) => ({ ...j, status: isReceiveForm ? 2 : 1 }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selOutlet.orgId) {
      API(controller.Supplier + action.GetAllByOrg).getById(
        selOutlet.orgId,
        (res) => {
          res.status === 200 &&
            setSplrs([
              ...res.result.map((j) => ({
                ...j,
                label: j.firstName + " " + j.lastName,
              })),
            ]);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  // Purchase Update Operation
  useEffect(() => {
    id &&
      API(controller.Stock + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((j) => ({
            ...j,
            ...val,
            user: "",
            outlet: "",
            org: "",
            recipientOutlet: "",
            stockProds: [
              ...val.stockProds.map((x) => ({
                ...x,
                user: "",
                org: "",
                outlet: "",
                img: x.isVariant
                  ? x.varProd && x.varProd.imgs[0] && x.varProd.imgs[0].imgUrl
                  : x.prod.imgs[0] && x.prod.imgs[0].imgUrl,
                name: x.isVariant
                  ? (x.varProd && x.varProd.product.name) +
                    " / " +
                    x.varProd.attrVal1 +
                    (x.varProd.attrVal2 && ` / ${x.varProd.attrVal2}`) +
                    (x.varProd.attrVal3 && ` / ${x.varProd.attrVal3}`)
                  : x.prod && x.prod.name,
                subtitle: x.isVariant
                  ? x.varProd && x.varProd.product.skuCode
                  : x.prod && x.prod.skuCode,
                totalPrice: x.qty * x.price,
              })),
            ],
          }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const GetDataFromServer = (val, onComplete) => {
    selOutlet &&
      API(controller.Product + action.FindProducts).post(
        {
          orgId: selOutlet.orgId,
          value: val,
        },
        onComplete
      );
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      if (res.status === 200) {
        var _stockProds = [];
        res.result
          .filter((j) => j.prodType !== 3)
          .forEach((prod) => {
            if (prod.prodType === 1) {
              _stockProds.push({
                ...prod,
                isVariant: false,
                id: prod.id,
                varProdId: null,
                img: prod.imgs && prod.imgs[0] && prod.imgs[0].imgUrl,
                label: prod.name,
                subtitle:
                  (prod.skuCode && `${prod.skuCode} | `) + `$${prod.splrPrice}`,
              });
            } else {
              prod.prodVariants.forEach((varProd) => {
                _stockProds.push({
                  ...varProd,
                  isVariant: true,
                  id: null,
                  varProdId: varProd.id,
                  img:
                    varProd.imgs && varProd.imgs[0] && varProd.imgs[0].imgUrl,
                  label: `${
                    prod.name +
                    " / " +
                    varProd.attrVal1 +
                    (varProd.attrVal2 && ` / ${varProd.attrVal2}`) +
                    (varProd.attrVal3 && ` / ${varProd.attrVal3}`)
                  }`,
                  subtitle:
                    (varProd.skuCode && `${varProd.skuCode} | `) +
                    `$${varProd.splrPrice}`,
                });
              });
            }
          });
        setSearchLi([..._stockProds]);
      }
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  const validateForm = () => {
    let temp = {};
    temp.recipientOutletId =
      values.recipientOutletId !== "" ? "" : "Recipient is required.";
    temp.splrId = values.splrId !== "" ? "" : "Supplier is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201 || res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: res.msg,
              onClick: () => navigate("/stock_control"),
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        }
      };

      if (values.id === 0) {
        API(controller.Stock + action.Post).post(
          BGNestedObjToFromData({
            ...values,
            stockProds: values.stockProds.map((j) => ({ ...j, prod: null })),
            outletId: selOutlet.id,
            orgId: selOutlet.orgId,
            userId: authUser.id,
          }),
          onComplete
        );
      } else {
        API(controller.Stock + action.Put).put(
          BGNestedObjToFromData({
            ...values,
            stockProds: values.stockProds.map((j) => ({ ...j, prod: null })),
          }),
          onComplete
        );
      }
    }
  };

  const handleReceiveSubmit = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title:
        "You’re about to receive this delivery which will update your inventory",
      subTitle: "This action is permanent and can’t be undone.",
      confirmBtnTitle: "Receive Delivery",
      cancelBtnTitle: "Don't Receive",
      confirmBtnColor: "success",
      onConfirm: () => {
        handleSubmit();
      },
    });
  };

  const handleScan = (e, searchVal) => {
    const onComplete = (res) => {
      if (res && res.result.length) {
        const _prod = res.result.filter((j) => j.prodType !== 3)[0];
        var isVariantProd = _prod.prodType === 2 ? true : false;

        const prod = isVariantProd
          ? _prod.prodVariants.find((j) => j.skuCode === searchVal)
          : _prod;

        prod
          ? handleSelect(null, {
              ...prod,
              splrId: _prod.splrId,
              isVariant: isVariantProd,
              label: isVariantProd
                ? `${
                    _prod.name +
                    " / " +
                    prod.attrVal1 +
                    (prod.attrVal2 && ` / ${prod.attrVal2}`) +
                    (prod.attrVal3 && ` / ${prod.attrVal3}`)
                  }`
                : prod.name,
            })
          : setFindErr("Product Not Found");
      }
    };
    e.target.value && GetDataFromServer(e.target.value, onComplete);
    setSearchVal("");
  };

  useEffect(() => {
    var newProd = values.stockProds[0];
    if (newProd && values.stockProds.some((j) => j.prevStock === "")) {
      API(controller.Inventory + action.GetInventory).post(
        ToFormData({
          id: newProd.isVariant ? newProd.varProdId : newProd.prodId,
          boolVal: newProd.isVariant,
        }),
        (res) => {
          if (res && res.result)
            setValues((j) => ({
              ...j,
              stockProds: [
                ...j.stockProds.map((x) =>
                  (
                    newProd.isVariant
                      ? x.varProdId === newProd.varProdId
                      : x.prodId === newProd.prodId
                  )
                    ? { ...x, prevStock: res.result }
                    : { ...x }
                ),
              ],
            }));
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stockProds.length]);

  const _handleProdSel = (prod) => {
    if (
      !values.stockProds.some(
        (j) => j.prodId === prod.id && j.varProdId === prod.varProdId
      )
    ) {
      setValues((x) => ({
        ...x,
        stockProds: [
          {
            name: prod.label,
            subtitle: prod.subtitle,
            prodId: prod.id,
            varProdId: prod.varProdId,
            qty: 1,
            splrPrice: prod.splrPrice,
            isVariant: prod.isVariant,
            img: prod.img,
            prod: prod,
            price: prod.splrPrice,
            totalPrice: prod.splrPrice,
            prevStock: "",
          },
          ...x.stockProds,
        ],
      }));
    } else if (
      values.stockProds.some(
        (j) => j.prodId === prod.id && j.varProdId === prod.varProdId
      )
    ) {
      setValues((x) => ({
        ...x,
        stockProds: x.stockProds.map((j) =>
          j.prodId === prod.id && j.varProdId === prod.varProdId
            ? { ...j, qty: parseFloat(j.qty) + 1 }
            : j
        ),
      }));
    }
    setFindErr("");
  };

  const handleSelect = (e, prod) => {
    if (values.splrId && prod.splrId !== values.splrId)
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "This product is not for the selected suppliers.",
        subTitle: "Do you still want to add this product?",
        confirmBtnColor: "primary",
        cancelBtnColor: "error",
        cancelBtnTitle: "No",
        onConfirm: () => {
          _handleProdSel(prod);
          setAlert({
            isOpen: false,
            type: "",
            title: "",
            subTitle: "",
          });
        },
      });
    else _handleProdSel(prod);

    if (prod) {
    }
  };

  const handleQty = (e, prod) => {
    const qty = e.target.value || 1;
    setValues((j) => ({
      ...j,
      stockProds: j.stockProds.map((x) => {
        var updQty = x.qty;
        var updTotalPrice = x.totalPrice;
        if (x.prodId === prod.prodId && x.varProdId === prod.varProdId) {
          updQty = qty;
          updTotalPrice = (qty * parseFloat(x.price)).toFixed(2);
        }

        return {
          ...x,
          qty: updQty,
          totalPrice: updTotalPrice,
        };
      }),
    }));

    setValues((j) => {
      return {
        ...j,
      };
    });
  };

  const handlePrice = (e, prod) => {
    const price = e.target.value || 0;
    setValues((j) => ({
      ...j,
      stockProds: j.stockProds.map((x) => {
        var updPrice = x.price;
        var updTotalPrice = x.totalPrice;
        if (x.prodId === prod.prodId && x.varProdId === prod.varProdId) {
          updPrice = price;
          updTotalPrice = (x.qty * parseFloat(price)).toFixed(2);
        }

        return {
          ...x,
          price: updPrice,
          totalPrice: updTotalPrice,
        };
      }),
    }));

    setValues((j) => {
      return {
        ...j,
      };
    });
  };

  const handleDlt = (prod) => {
    setValues((j) => ({
      ...j,
      stockProds: [
        ...j.stockProds.filter(
          (x) => x.prodId !== prod.prodId || x.varProdId !== prod.varProdId
        ),
      ],
    }));
  };

  return (
    <>
      <Paper>
        <div className="d-flex j-between a-center p-10">
          <div className="d-flex a-center g-10">
            <div>
              <IconButton size="small" onClick={() => navigate(-1)}>
                <ArrowBackRounded />
              </IconButton>
            </div>

            <div>
              <h1 className="form-title fw-4 gray-color tx-upp">
                {isReceiveForm ? "Receive Stock" : "New Purchase Order"}
              </h1>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              loading={loading}
              onClick={() =>
                isReceiveForm ? handleReceiveSubmit() : handleSubmit()
              }
            >
              {id ? "Save Changes" : "Submit"}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="p-15 mt-15">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputAuto
                label="Supplier"
                name="splrId"
                required={true}
                value={values.splrId}
                error={errors.splrId}
                onChange={handleInpChg}
                options={splrs}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputAuto
                label="Delivery Recipient"
                name="recipientOutletId"
                required={true}
                value={values.recipientOutletId}
                error={errors.recipientOutletId}
                onChange={handleInpChg}
                options={outlets}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Supplier Invoice Number"
                name="splrInv"
                value={values.splrInv}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Order Number"
                name="orderNum"
                value={values.orderNum}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label="Delivery Date"
                name="deliveryDate"
                value={values.deliveryDate}
                onChange={(date) =>
                  handleInpDate(
                    "deliveryDate",
                    new Date(date).toLocaleDateString()
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Note"
                name="note"
                multiline
                rows={3}
                value={values.note}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex a-center pl-5 mt-20">
                <span
                  className="gray-color tx-upp fw-5"
                  style={{ minWidth: "100px" }}
                >
                  Products
                </span>
                <div className="bs-secondary w-100pr" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <InputAuto
                label="Search or Scan Product"
                value={searchVal}
                onChange={handleSelect}
                onInputChange={handleSearch}
                inputValue={searchVal}
                onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
                classes={{ paper: "shadow-sm" }}
                options={searchLi}
                error={findErr}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer className="bs-secondary br-15 bg-inherit">
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>
                        <span className="m-0 p-0">Current Inventory</span>
                      </TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Cost Price</TableCell>
                      <TableCell>Total Cost</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.stockProds.map((prod, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <div
                            className="d-flex a-center g-10"
                            style={{ minWidth: "300px" }}
                          >
                            <img
                              src={prod.img ? prod.img : no_img}
                              alt="product_img"
                              className="img-fluid w-60 obj-cover br-10 shadow-xs"
                            />
                            <div className="mr-20 d-grid ml-10">
                              <span className="fs-15 fw-5">{prod.name}</span>
                              <span className="fs-12 gray-color">
                                {prod.subtitle}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <span className="fs-16">{prod.prevStock || 0}</span>
                        </TableCell>
                        <TableCell>
                          <Input
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: "100px" }}
                            onClick={(e) => e.target.select()}
                            onChange={(e) => handleQty(e, prod)}
                            value={prod.qty}
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 8))
                            }
                          />
                        </TableCell>

                        <TableCell>
                          <Input
                            variant="outlined"
                            placeholder="$ 0.00"
                            size="small"
                            sx={{ minWidth: "100px" }}
                            value={prod.price}
                            onClick={(e) => e.target.select()}
                            onChange={(e) => handlePrice(e, prod)}
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 8))
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            className="fs-18 gray-color"
                            sx={{ minWidth: "100px" }}
                          >
                            $&nbsp;{prod.totalPrice}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              onClick={() => handleDlt(prod)}
                            >
                              <ClearRounded className="fs-26" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell className="fs-16 fw-6 gray-color tx-upp">
                        Total Cost:
                      </TableCell>
                      <TableCell className="fs-18 fw-6 gray-color tx-upp">
                        ${" "}
                        {values.stockProds &&
                          values.stockProds
                            .map((j) => parseFloat(j.totalPrice))
                            .reduce((x, y) => x + y, 0)
                            .toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default OrderAndReceiveForm;
