import React, { useState } from "react";
import { Select } from "../../controls";
import DropLi from "../../hooks/DropLi";
import { Box, Paper } from "@mui/material";

const HomeRepFilter = () => {
  const { PeriodicType } = DropLi();
  const [fltDate, setFltDate] = useState(3);

  const handleDate = (e) => {
    setFltDate(e.target.value);
  };

  return (
    <Paper className="p-10">
      <div className="d-flex flex-wrap g-15 j-between a-center">
        <div className="d-grid g-5">
          <span className="fs-14 gray-color">Welcome 🎉</span>
          <span className="fs-20 gray-color fw-5">Mohammad Ismile</span>
        </div>

        <Box sx={{ width: { md: "250px", xs: "100%" } }}>
          <Select
            variant="outlined"
            value={fltDate}
            onChange={handleDate}
            options={PeriodicType}
          />
        </Box>
      </div>
    </Paper>
  );
};

export default HomeRepFilter;
