import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 24,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(15px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--primary-color)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const InputSwitch = (props) => {
  const {
    label,
    checked,
    name,
    onChange,
    error = null,
    fontSize = "18px",
    ...other
  } = props;

  return (
    <FormGroup className="d-contents">
      <FormControlLabel
        label={
          <Typography sx={{ fontSize: fontSize, color: "var(--gray-color)" }}>
            {label}
          </Typography>
        }
        sx={{
          ml: "0px",
          color: "var(--dark-color)",
        }}
        control={
          <AntSwitch
            sx={{ m: 1 }}
            name={name}
            checked={checked}
            onChange={onChange}
          />
        }
        {...other}
      />
      {error && (
        <FormHelperText className="color-danger">{error}</FormHelperText>
      )}
    </FormGroup>
  );
};

export default InputSwitch;
