import React from "react";
import { Model, UseForm } from "../../../hooks";
import { Button, Input } from "../../../controls";

const CartProdForm = (props) => {
  const { handleViewDtl, data } = props;
  const { CartProdMdl } = Model();
  const { values, handleInpChg } = UseForm(CartProdMdl);

  return (
    <div className="d-grid g-15 mt-20">
      <div className="d-flex g-5">
        <Input
          label="Quantity"
          name="qty"
          value={values.qty}
          type="number"
          variant="outlined"
          size="small"
          onChange={handleInpChg}
        />
        <Input
          label="Price"
          name="price"
          value={values.price}
          type="number"
          variant="outlined"
          size="small"
          onChange={handleInpChg}
        />
        <Input
          label="Discount(%)"
          name="discount"
          value={values.discount}
          type="number"
          variant="outlined"
          size="small"
          onChange={handleInpChg}
        />
      </div>
      <Input
        label="Note"
        name="note"
        value={values.note}
        variant="outlined"
        size="small"
        multiline
        rows={2}
        onChange={handleInpChg}
      />
      <Button size="small" variant="text" onClick={() => handleViewDtl(data)}>
        Show Inventory & Details
      </Button>
    </div>
  );
};

export default CartProdForm;
