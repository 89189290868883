import React, { createContext, useEffect, useState } from "react";
import theme from "./utils/theme";
import { ThemeProvider } from "@mui/material";
import { Loader } from "./controls";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./styles/App.scss";
import "./styles/Style.scss";
import SiteRoute from "./pages/SiteRoute";
import { Login, Forgot } from "./components/auth";
import { API, action, controller } from "./api/api";

export const AppContext = createContext({});

const App = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [authUser, setAuthUser] = useState();
  const [selOutlet, setSelOutlet] = useState({});
  const [outlets, setOutlets] = useState([]);

  useEffect(() => {
    setLoader(true);
    API(controller.Auth + action.GetAuthUser).get((res) => {
      if (res && res.status === 200 && res.result) {
        setAuthUser(res.result);
      } else navigate("/auth");
      setLoader(false);
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check user in the auth location?
    authUser && window.location.pathname === "/auth" && navigate("/home");
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          loader,
          setLoader,
          authUser,
          setAuthUser,
          selOutlet,
          setSelOutlet,
          outlets,
          setOutlets,
        }}
      >
        <Loader show={loader} />
        <Routes>
          <Route
            path="/*"
            element={authUser ? <SiteRoute /> : !loader && <Auth />}
          />
        </Routes>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

const Auth = () => (
  <Routes>
    <Route path="/" element={<Navigate to="auth" />} />
    <Route path="/auth">
      <Route path="forgot" element={<Forgot />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export default App;
