import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Alert, Form } from "../../../layout";
import { Button, InputPass } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { PassValidation } from "../../../utils";
import { useNavigate } from "react-router-dom";

const ChangePass = (props) => {
  const { setActiveStep, setForgotVal, forgotVal } = props;
  const { ForgotMdl } = Model();
  const navigate = useNavigate();
  const [err] = useState();
  const [loading, setLoading] = useState(false);
  const { values, setValues, errors, setErrors, handleInpPass, passValid } =
    UseForm(ForgotMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.password = values.password !== "" ? "" : "Password is required.";
    temp.confirmPass =
      values.confirmPass !== ""
        ? values.confirmPass === values.password
          ? ""
          : "Password and Confirm password not match"
        : "Confirm Password is required.";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(false);
      navigate("/dashboard");
      values.forgotStep = 3;
    }
  };

  useEffect(() => {
    setValues(forgotVal);
    return () => {
      setValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setForgotVal({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Form noValidate onSubmit={submitForm}>
        <Grid container className="p-15 pt-0" spacing={2}>
          <Grid item xs={12} className="tx-center mt-30 pb-30">
            <h2 className="fs-22 fw-6 tx-upp h gray-color">Change Password</h2>
            <div className="pos-relative d-flex j-center mt-5">
              <div className="border-bottom w-60" />
            </div>
          </Grid>
          {err && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="error">
                <span className="fw-6 tx-upp"> Error:&nbsp;</span> {err}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputPass
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInpPass}
              error={errors.password}
              className="input-primary w-100pr"
            />
            <PassValidation {...{ password: values.password, passValid }} />
          </Grid>
          <Grid item xs={12}>
            <InputPass
              label="Confirm Password"
              name="confirmPass"
              value={values.confirmPass}
              onChange={(e) => handleInpPass(e, true)}
              error={errors.confirmPass}
              className="input-primary w-100pr"
            />
          </Grid>
          <Grid item xs={12} className="text-center mt-20">
            <Button
              type="submit"
              loading={loading}
              size="large"
              className="w-100pr"
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Form>
      <Alert
        onClick={() => setActiveStep(undefined)}
        alert={alert}
        setAlert={setAlert}
      />
    </>
  );
};

export default ChangePass;
