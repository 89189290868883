import { Grid } from "@mui/material";

import React from "react";
import Widget from "./Widget";
import {
  AddShoppingCartRounded,
  IndeterminateCheckBoxRounded,
  KeyboardReturnRounded,
  ShoppingCartRounded,
} from "@mui/icons-material";

const Widgets = () => {
  return (
    <Grid container spacing={3} className="tx-upp">
      <Grid item xs={12} md={6} lg={3}>
        <Widget
          title="total sale"
          number="$0.00"
          icon={<ShoppingCartRounded className="fs-35" />}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Widget
          title="purchases"
          number="$0.00"
          icon={<AddShoppingCartRounded className="fs-35" />}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Widget
          title="total expenses"
          number="$0.00"
          icon={<IndeterminateCheckBoxRounded className="fs-35" />}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Widget
          title="sales return"
          number="$0.00"
          icon={<KeyboardReturnRounded className="fs-35" />}
        />
      </Grid>
    </Grid>
  );
};

export default Widgets;
