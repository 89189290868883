import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API, action, controller } from "../../../api/api";
import { Divider, Grid, IconButton, TableCell } from "@mui/material";
import {
  ArrowBackRounded,
  ClearRounded,
  DeleteRounded,
  DoneAllRounded,
} from "@mui/icons-material";
import { Alert, Dialog, Table } from "../../../layout";
import { Button } from "../../../controls";
import CancelOrderForm from "./CancelOrderForm";

const ViewOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [prods, setProds] = useState([]);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    id &&
      API(controller.Stock + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) setOrder(res.result);
        else setOrder({});
      });

    return () => {};
  }, [id]);

  useEffect(() => {
    order &&
      setProds([
        ...order.stockProds.map((j) => {
          var data = {};
          if (j.isVariant && j.varProd) {
            data = {
              label:
                (j.varProd && j.varProd.product.name) +
                " / " +
                j.varProd.attrVal1 +
                (j.varProd.attrVal2 && ` / ${j.varProd.attrVal2}`) +
                (j.varProd.attrVal3 && ` / ${j.varProd.attrVal3}`),
              sku: j.varProd.skuCode,
              splrCode: j.varProd.splrCode,
              rtlPrice: j.varProd.rtlPrice,
            };
          } else if (j.prod) {
            data = {
              label: j.prod.name,
              sku: j.prod.skuCode,
              splrCode: j.prod.splrCode,
              rtlPrice: j.prod.rtlPrice,
            };
          }

          return { ...j, ...data };
        }),
      ]);

    return () => {};
  }, [order]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    setShowCancelForm(true);
  };

  const handleDlt = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete the order?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
              onClick: () => navigate("/stock_control"),
            });
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        API(controller.Stock).Delete(order.id, onComplete);
      },
    });
  };

  const handleMarkReceived = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure?",
      subTitle:
        "You want to mark this order as completed. This action is permanent and can’t be undone.",
      confirmBtnColor: "success",
      onConfirm: () => {
        API(controller.Stock + action.MarkAsReceived).getById(
          order.id,
          (res) => {
            if (res && res.result && res.status === 200) {
              setOrder((j) => ({ ...j, ...res.result }));
              setAlert({
                isOpen: true,
                type: "success",
                title: "Success",
                subTitle: res.msg,
              });
            } else
              setAlert({
                isOpen: true,
                type: "error",
                title: "Error",
                subTitle: res.msg,
              });
          }
        );
      },
    });
  };

  const HeaderCom = (val) => (
    <div className="d-flex g-10 flex-wrap">
      <IconButton onClick={handleBack}>
        <ArrowBackRounded />
      </IconButton>
      {order.status === 1 && (
        <>
          <Button
            startIcon={<DoneAllRounded />}
            variant="outlined"
            color="success"
            onClick={() => handleMarkReceived()}
          >
            Mark As Complete
          </Button>
          <Button
            startIcon={<ClearRounded />}
            variant="outlined"
            color="warning"
            onClick={() => handleCancel()}
          >
            Cancel Order
          </Button>
          <Button
            startIcon={<DeleteRounded />}
            variant="outlined"
            color="error"
            onClick={() => handleDlt(val.id)}
          >
            Delete Order
          </Button>
        </>
      )}
    </div>
  );

  const renderDetails = () => {
    return (
      <>
        <Grid item xs={12}>
          <div className="d-flex g-10 a-center">
            <span>
              Order Id: <span className="fs-26">{order.orderNum}</span>
            </span>

            <div className="tx-center fs-14 white-color w-fit">
              {order.status === 1 ? (
                <div className="bg-primary fs-18 br-25 pl-10 pr-10 pt-5 pb-5">
                  <span>Open</span>
                </div>
              ) : order.status === 2 ? (
                <div className="bg-success fs-18 br-25 pl-10 pr-10 pt-5 pb-5">
                  <span>Received</span>
                </div>
              ) : (
                order.status === 3 && (
                  <div className="bg-warning fs-18 br-25 pl-10 pr-10 pt-5 pb-5">
                    <span>Cancelled</span>
                  </div>
                )
              )}
            </div>
          </div>
          <Divider className="pt-10" />
        </Grid>
        <Grid item xs={12} md={5}>
          <div className="d-grid g-10 fs-14">
            <div className="d-flex flex-wrap">
              <span className="fw-5" style={{ width: "40%" }}>
                Deliver to
              </span>
              <span className="fw-4 gray-color" style={{ width: "60%" }}>
                {order.recipientOutlet && order.recipientOutlet.outletName}
              </span>
            </div>

            <div className="d-flex flex-wrap">
              <span className="fw-5" style={{ width: "40%" }}>
                Supplier
              </span>
              {order.splr && (
                <span className="fw-4 gray-color" style={{ width: "60%" }}>
                  {order.splr.company} (
                  {order.splr.firstName + " " + order.splr.lastName})
                </span>
              )}
            </div>
            <div className="d-flex flex-wrap">
              <span className="fw-5" style={{ width: "40%" }}>
                Supplier invoice
              </span>
              <span className="fw-4 gray-color" style={{ width: "60%" }}>
                {order.splrInv}
              </span>
            </div>
            <div className="d-flex flex-wrap">
              <span className="fw-5" style={{ width: "40%" }}>
                Created by
              </span>
              {order.user && (
                <span className="fw-4 gray-color" style={{ width: "60%" }}>
                  {order.user &&
                    order.user.firstName + " " + order.user.lastName}
                </span>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className="d-grid g-10 fs-14">
            <div className="d-flex flex-wrap">
              <span className="fw-5" style={{ width: "40%" }}>
                Created
              </span>
              <span className="fw-4 gray-color" style={{ width: "60%" }}>
                {new Date(order.createDate).toDateString()}
              </span>
            </div>

            <div className="d-flex flex-wrap">
              <span className="fw-5" style={{ width: "40%" }}>
                Delivery due
              </span>
              <span className="fw-4 gray-color" style={{ width: "60%" }}>
                {new Date(order.deliveryDate).toDateString()}
              </span>
            </div>

            {order.receiveDate && (
              <div className="d-flex flex-wrap">
                <span className="fw-5" style={{ width: "40%" }}>
                  Received
                </span>
                <span className="fw-4 gray-color" style={{ width: "60%" }}>
                  {new Date(order.receiveDate).toDateString()}
                </span>
              </div>
            )}

            {order.status === 3 && (
              <>
                <Divider />

                <div className="d-flex flex-wrap">
                  <span className="fw-5" style={{ width: "40%" }}>
                    Cancel Date
                  </span>
                  <span className="fw-4 gray-color" style={{ width: "60%" }}>
                    {new Date(order.cancelDate).toDateString()}
                  </span>
                </div>
                <div className="d-flex flex-wrap">
                  <span className="fw-5" style={{ width: "40%" }}>
                    Cancel User
                  </span>
                  <span className="fw-4 gray-color" style={{ width: "60%" }}>
                    {order.cancelUser &&
                      order.cancelUser.firstName +
                        " " +
                        order.cancelUser.lastName}
                  </span>
                </div>
                <div>
                  <span className="fw-5 mr-10 tx-und">Cancel Reason:</span>
                  <span
                    className="fw-4 gray-color pre-wrap"
                    style={{ lineHeight: "1.4rem" }}
                  >
                    {order.cancelReason}
                  </span>
                </div>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </>
    );
  };

  const renderProdTable = () => {
    return (
      <Grid item xs={12}>
        <Table
          ActionComp={() => <></>}
          HeaderComp={HeaderCom}
          tblHead={[
            { id: "order", label: "#" },
            { id: "label", label: "Product" },
            { id: "sku", label: "SKU" },
            { id: "splrCode", label: "Supplier Code" },
            { id: "prevStock", label: "Stock" },
            { id: "qty", label: "Ordered" },
            { id: "costPrice", label: "Cost Price" },
            { id: "totalPrice", label: "Total Price" },
            { id: "retailPrice", label: "Retail Price" },
            { id: "totalRetailPrice", label: "Total Retail Price" },
          ]}
          tblData={prods.map((prod, ind) => {
            return {
              ...prod,
              order: ind + 1,
              costPrice: "$ " + prod.price,
              totalPrice: "$ " + prod.qty * prod.price,
              retailPrice: "$ " + prod.rtlPrice,
              totalRetailPrice: "$ " + prod.qty * prod.rtlPrice,
              prevStock: prod.prevStock || 0,
            };
          })}
          FooterRow={() => (
            <>
              <TableCell className="fs-16 fw-5">Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className="fs-16 fw-5">
                {prods.map((j) => j.prevStock).reduce((x, y) => x + y, 0)}
              </TableCell>
              <TableCell className="fs-16 fw-5">
                {prods.map((j) => j.qty).reduce((x, y) => x + y, 0)}
              </TableCell>
              <TableCell className="fs-16 fw-5">
                ${" "}
                {prods
                  .map((j) => j.price)
                  .reduce((x, y) => x + y, 0)
                  .toFixed(2)}
              </TableCell>
              <TableCell className="fs-16 fw-5">
                ${" "}
                {prods
                  .map((j) => j.qty * j.price)
                  .reduce((x, y) => x + y, 0)
                  .toFixed(2)}
              </TableCell>
              <TableCell className="fs-16 fw-5">
                ${" "}
                {prods
                  .map((j) => j.rtlPrice)
                  .reduce((x, y) => x + y, 0)
                  .toFixed(2)}
              </TableCell>
              <TableCell className="fs-16 fw-5">
                ${" "}
                {prods
                  .map((j) => j.qty * j.rtlPrice)
                  .reduce((x, y) => x + y, 0)
                  .toFixed(2)}
              </TableCell>
            </>
          )}
        />
      </Grid>
    );
  };

  return (
    <>
      {order ? (
        <Grid container spacing={3} className="p-10">
          {renderDetails()}
          {renderProdTable()}
        </Grid>
      ) : (
        "Loading..."
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {showCancelForm && (
        <Dialog show={showCancelForm} onClose={false} maxWidth="sm">
          <CancelOrderForm
            {...{ setOrder, order, setShowForm: setShowCancelForm, setAlert }}
          />
        </Dialog>
      )}
    </>
  );
};

export default ViewOrder;
