import React from "react";
import { Grid, Paper } from "@mui/material";

const Details = (props) => {
  const { cstmr, gender, accBalType } = props;
  return (
    <Paper className="pt-5 bg-white shadow-xs">
      {cstmr ? (
        <Grid container spacing={3} className="p-15 dark-color mb-10">
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
              Customer Id:
            </span>
            <span className="fs-16 fw-4 gray-color">{cstmr.id}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
              Customer Code:
            </span>
            <span className="fs-16 fw-4 gray-color">{cstmr.cstmrCode}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">
              Customer Group:
            </span>
            <span className="fs-16 fw-4 gray-color">
              {cstmr.cstmrGroup && cstmr.cstmrGroup.name}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">First Name:</span>
            <span className="fs-16 fw-4 gray-color gray-color">
              {cstmr.firstName}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Last Name:</span>
            <span className="fs-16 fw-4 gray-color"> {cstmr.lastName}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Gender:</span>
            <span className="fs-16 fw-4 gray-color"> {gender}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Phone:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={`tel:${cstmr.phone}`}
            >
              {cstmr.phone}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Email:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={`mailto:${cstmr.email}`}
            >
              {cstmr.email}
            </a>
          </Grid>

          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "70px" }}
              >
                Address
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">
              Address Line 1:
            </span>
            <span className="fs-16 fw-4 gray-color">{cstmr.address1}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">
              Address Line 2:
            </span>
            <span className="fs-16 fw-4 gray-color">{cstmr.address2}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">City:</span>
            <span className="fs-16 fw-4 gray-color">{cstmr.city}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">State:</span>
            <span className="fs-16 fw-4 gray-color">{cstmr.state}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">ZIP:</span>
            <span className="fs-16 fw-4 gray-color">{cstmr.zip}</span>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "150px" }}
              >
                Additional Details
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>

          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Company Name</span>
            <span className="fs-16 fw-4 gray-color">{cstmr.company}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">
              Date of Birth
            </span>
            <span className="fs-16 fw-4 gray-color">{cstmr.dob}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Website:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={cstmr.website}
              target="_blank"
              rel="noreferrer"
            >
              {cstmr.website}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Twitter:</span>
            <a
              className="link fs-16 fw-4 gray-color"
              href={cstmr.twitter}
              target="_blank"
              rel="noreferrer"
            >
              {cstmr.twitter}
            </a>
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex a-center mt-20">
              <span
                className="gray-color tx-upp fw-5 fs-14"
                style={{ minWidth: "80px" }}
              >
                Settings
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">
              Allow an on account balance:
            </span>
            <span className="fs-16 fw-4 gray-color">{accBalType}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">
              Balance Limit:
            </span>
            <span className="fs-16 fw-4 gray-color">${cstmr.balLimit}</span>
          </Grid>
          <Grid item xs={12} md={4} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color">Tax:</span>
            <span className="fs-16 fw-4 gray-color">
              {cstmr.tax && cstmr.tax.name}
            </span>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </Paper>
  );
};

export default Details;
