import { Divider, Grid, Button, Typography } from '@mui/material'
import React from 'react'

const InputProdType = (props) => {
  const { name, value, onChange, options } = props;

  const handleChange = (val) => {
    var e = {
      target: {
        value: val || "",
        name: name,
      }
    };
    onChange(e)
  }

  return (
    <div >
      <Grid container spacing={2}  justifyContent="center">
        {options.map((item, ind) => (
          <Grid item xs={12} sm={6} lg={4} key={ind} >
            <Button  onClick={() => handleChange(item.id)} variant={item.id === value ? "text" : "outlined"}  sx={{
              height: "100%",
              alignItems: "start",
     
            }}>
              <div className='d-grid'>
                  <div className='pb-5'>
                <Typography className='fs-20 fw-5'>{item.label}</Typography>
              </div>
              <Divider className='mb-20' />
              <div>
                <Typography>
                  {item.subtitle}
                </Typography>
              </div>

              </div>
            
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>


  )
}

export default InputProdType