import { ClearRounded } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React from "react";
import { Tabs } from "../../../../layout";
import InventoryDtl from "./Inventory";
import Pricing from "./Pricing";
import Details from "./Details";

const InvDetails = (props) => {
  const { setShowForm, data } = props;
  const handleClose = () => {
    setShowForm(false);
  };
  return (
    <>
      {data && (
        <Grid container spacing={3} className="p-15 dark-color mb-10">
          {/* Product Short Intro */}
          <Grid item xs={12}>
            <div className="mb-15 d-flex j-between a-center">
              <div className="d-flex a-center g-10">
                <img
                  src={data.img}
                  className="br-4 h-50 w-50 obj-cover"
                  alt="product_img"
                />
                <div className="d-grid">
                  <span className="fs-18 fw-5 gray-color mb-5">
                    Product Name
                  </span>
                  <span className="fs-12">234567</span>
                </div>
              </div>
              <div>
                <IconButton onClick={handleClose}>
                  <ClearRounded />
                </IconButton>
              </div>
            </div>
          </Grid>

          {/* Inventory Details */}
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
              Brand
            </span>
            <span className="fs-16 fw-4 gray-color">Brand Name</span>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
              Supplier
            </span>
            <span className="fs-16 fw-4 gray-color">Supplier Name</span>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
              Inventory
            </span>
            <span className="fs-16 fw-4 gray-color">40</span>
          </Grid>
          <Grid item xs={12} md={3} className="d-grid">
            <span className="tx-xs tx-upp mb-5 grayMD-color grayMD-color">
              Retail price
            </span>
            <span className="fs-16 fw-4 gray-color">$60</span>
          </Grid>

          {/* Details */}
          <Grid item xs={12}>
            <Tabs
              className="mt-30"
              header={[
                { label: "Inventory", icon: "" },
                { label: "Pricing", icon: "" },
                { label: "Details", icon: "" },
              ]}
            >
              <InventoryDtl />
              <Pricing />
              <Details />
            </Tabs>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InvDetails;
