import { Grid, InputAdornment, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { API, action, controller } from "../../../api/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { AppContext } from "../../../App";

const AddEditTax = (props) => {
  const { setAlert, setShowForm, updData, setTaxes } = props;
  const { selOutlet, authUser } = useContext(AppContext);
  const { TaxMdl } = Model();
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(TaxMdl);

  useEffect(() => {
    updData && setValues((x) => ({ ...x, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : "Name is required.";
    temp.rate = values.rate !== "" ? "" : "Rate is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && (res.status === 201 || res.status === 200)) {
          if (setTaxes && res.result.id) {
            updData
              ? setTaxes((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setTaxes((x) => [res.result, ...x]);
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: `Tax ${updData ? "Updated" : "Added"} Successfully.`,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.orgId = selOutlet.orgId;
        values.userId = authUser.id;
        API(controller.Tax + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else
        API(controller.Tax + action.Put).put(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <Grid container spacing={2} className="p-15">
            <Grid item xs={12} className="tx-center p-20 pt-30 w-100pr">
              <span className="fw-4 fs-26 tx-upp gray-color">
                {updData ? "Update" : "Add"} Tax
              </span>
              <div className="pos-relative d-flex j-center mt-5">
                <div className="border-bottom w-60" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Name"
                name="name"
                value={values.name}
                error={errors.name}
                inputProps={{ maxLength: 150 }}
                required={true}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Rate"
                name="rate"
                value={values.rate}
                error={errors.rate}
                type="number"
                required={true}
                onChange={handleInpChg}
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 3))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex g-10 mt-20">
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button fullWidth size="large" loading={loading} type="submit">
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

export default AddEditTax;
