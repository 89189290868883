import { Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { Button, Input } from "../../../controls";

import { Form } from "../../../layout";
import { API, action, controller } from "../../../api/api";
import { ToFormData } from "../../../hooks/Method";

const CancelOrderForm = (props) => {
  const { setAlert, setShowForm, order, setOrder } = props;
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (order && reason) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && res.status === 200) {
          setOrder && setOrder((j) => ({ ...j, ...res.result }));
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      API(controller.Stock + action.CancelOrder).post(
        ToFormData({
          id: order.id,
          value: reason,
        }),
        onComplete
      );
    } else if (!reason) setErrors("Cancel reason is required");
    else
      setErrors("Order data not found. Please refresh the page and try again.");
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const handleChgReason = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper sx={{ maxWidth: "500px" }}>
          <Grid container spacing={2} className="p-15">
            <Grid item xs={12} className="tx-center p-20 pt-30 w-100pr">
              <span className="fw-4 fs-26 tx-upp gray-color">Cancel Order</span>
              <div className="pos-relative d-flex j-center mt-5">
                <div className="border-bottom w-60" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Cancel Reason"
                value={reason}
                error={errors}
                multiline
                rows={4}
                onChange={handleChgReason}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex g-10 mt-20">
                <Button fullWidth size="large" loading={loading} type="submit">
                  Submit
                </Button>
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

export default CancelOrderForm;
