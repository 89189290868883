import React from "react";
import RetailFilter from "./RetailFilter";
import Widget from "./Widget";
import { Grid } from "@mui/material";
import { Table } from "../../../layout";

const RetailReport = () => {
  return (
    <>
      <div className="mb-30">
        <RetailFilter />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Widget
            title="Revenue"
            total="$0.00"
            url=""
            sales={[100, 300, 250, 150, 300, 250, 200]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="Sale Count"
            total="0"
            url=""
            sales={[300, 250, 250, 300, 300, 250, 200]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="Customer Count"
            url=""
            total="0"
            sales={[150, 250, 250, 250, 300, 250, 250]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="Gross Profit"
            url=""
            total="$0.00"
            sales={[50, 300, 350, 150, 300, 99, 200]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="Discounted"
            url=""
            total="$0.00"
            sales={[200, 100, 400, 300, 300, 350, 200]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="Discounted %"
            url=""
            total="0.00%"
            sales={[100, 300, 250, 150, 300, 250, 200]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="AVG.Sale Value"
            url=""
            total="$0.00"
            sales={[100, 50, 200, 350, 300, 250, 400]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget
            title="AVG.Items Per Sale"
            url=""
            total="0"
            sales={[200, 300, 100, 300, 250, 99, 200]}
          />
        </Grid>

        {/* Product Sold  */}
        <Grid item xs={12}>
          <div className="mt-20">
            <Table
              ActionComp={() => <></>}
              HeaderComp={() => (
                <div className="ml-10">
                  <span className="fw-5 tx-upp fs-20 gray-color">
                    Products Sold
                  </span>
                </div>
              )}
              tblHead={[
                { id: "prod", label: "Product" },
                { id: "revenue", label: "Revenue" },
                { id: "itemSold", label: "Items Sold" },
                { id: "discounted", label: "Discounted" },
              ]}
              tblData={[]}
            />
          </div>
        </Grid>
        {/* Top Sales Product  */}
        <Grid item xs={12}>
          <div className="mt-20">
            <Table
              ActionComp={() => <></>}
              HeaderComp={() => (
                <div className="ml-10">
                  <span className="fw-5 tx-upp fs-20 gray-color">
                    Top Sales Product
                  </span>
                </div>
              )}
              tblHead={[
                { id: "user", label: "User" },
                { id: "revenue", label: "Revenue" },
                { id: "saleCount", label: "Sale Count" },
                { id: "itemSold", label: "Items Sold" },
                { id: "avgSaleVal", label: "AVG. Sale Value" },
                { id: "avgItemPerSale", label: "AVG. Items per sale" },
              ]}
              tblData={[]}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RetailReport;
