import React from "react";
import SearchBox from "./SearchBox";
import Body from "./Body";

const Home = () => {
  return (
    <>
      <div>
        <SearchBox />
      </div>
      <div>
        <Body />
      </div>
    </>
  );
};

export default Home;
