import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../../layout";
import { IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../controls";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";

const Outlets = () => {
  const navigate = useNavigate();
  const { selOutlet, setLoader } = useContext(AppContext);
  const [outlets, setOutlets] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    if (selOutlet.id) {
      API(controller.Outlet + action.GetAllByOrg).getById(
        selOutlet.orgId,
        (res) => {
          res && res.status === 200 && setOutlets([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  const handleAddEdit = (id) => {
    navigate(`/outlet_form/${id || ""}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this outlet?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setOutlets((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        API(controller.Outlet).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val.id)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
          className="mr-5 p-5"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Outlet</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "outletName", label: "Outlet Name" },
          { id: "taxPct", label: "Tax Parcent" },
          { id: "phone", label: "Phone" },
          { id: "email", label: "Email" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={outlets.map((j) => {
          return {
            ...j,
            taxPct: j.tax && `${j.tax.rate}%`,
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Outlets;
