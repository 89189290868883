import React, { createContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import Actions from "./Actions";
import Cart from "./Cart";
import { Route, Routes, useNavigate } from "react-router-dom";
import RefundForm from "./RefundForm";
import Home from "./Home";
import Checkout from "./Checkout";
import { Model } from "../../../hooks";

export const CartContext = createContext({});

const Sell = () => {
  const { SellMdl } = Model();
  const [cartData, setCartData] = useState(SellMdl);
  const navigate = useNavigate();

  const handleClearCart = () => {
    navigate("/dashboard/sell");
  };

  return (
    <div>
      <CartContext.Provider value={{ cartData, setCartData }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                height: {
                  sm: "calc(100vh - 215px)",
                  xl: "calc(100vh - 225px)",
                },
                overflow: "auto",
                borderRadius: "0.8rem",
              }}
            >
              <Routes>
                <Route
                  path="/refund"
                  element={<RefundForm {...{ handleClearCart }} />}
                />

                <Route
                  path="checkout"
                  element={
                    <Checkout
                      {...{
                        handleClearCart,
                      }}
                    />
                  }
                />

                <Route index element={<Home />} />
              </Routes>
            </Box>

            <div>
              <Actions />
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <Cart />
          </Grid>
        </Grid>
      </CartContext.Provider>
    </div>
  );
};

export default Sell;
