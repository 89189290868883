import { Container, Paper } from "@mui/material";
import React from "react";
import { Button } from "../../../controls";

const Status = () => {
  return (
    <Container maxWidth="md">
      <Paper className="bg-white p-15 gray-color">
        <div className="mb-20">
          <span className="fs-24 gray-color border-left tx-upp">Status</span>
        </div>

        <div className="d-grid g-5">
          <span className="fs-18 fw-5">Reset local data</span>
          <span>
            We keep a copy of some of your store data in your web browser so you
            can keep selling if you lose your Internet connection. Sometimes,
            this gets out of sync. Resetting it can help if you're having
            trouble with your system.
          </span>
          <div className="mt-20">
            <Button size="large" color="error">
              Reset Data
            </Button>
          </div>
        </div>
      </Paper>
    </Container>
  );
};

export default Status;
