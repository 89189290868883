import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import * as React from "react";
import { Fragment } from "react";

const Accordion = (props) => {
  const { items, ...other } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {items.map((item, i) => (
        <Fragment key={i}>
          <MuiAccordion
            expanded={expanded === `panelId:${i}`}
            onChange={handleChange(`panelId:${i}`)}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              m: "0px",
              "&.MuiPaper-root.MuiAccordion-root:before": {
                display: "none",
              },
            }}
            {...other}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                flexDirection: "row-reverse",
                p: 0,

                m: "0px !important",
                "& .MuiSvgIcon-root": {
                  m: "10px",
                },
                "& .MuiAccordionSummary-content": {
                  flex: "1 0 auto",
                  margin: "3px",
                },
                "& .Mui-expanded": {
                  margin: "0px !important",
                },
                "&.Mui-expanded": {
                  minHeight: "0px !important",
                },
              }}
            >
              {item.header}
            </AccordionSummary>
            <AccordionDetails>{item.body}</AccordionDetails>
          </MuiAccordion>
          <Divider />
        </Fragment>
      ))}
    </div>
  );
};

export default Accordion;
