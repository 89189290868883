import { Divider } from "@mui/material";
import React from "react";

const ProdInv = () => {
  return (
    <>
      <div className="bg-white shadow-xs br-12">
        <div className="d-flex j-between  p-15">
          <span className="fw-5">Outlet</span>
          <span className="fw-5">Current Inventory</span>
        </div>
        <Divider />
        <div className="d-flex j-between  p-15">
          <span className="gray-color">Main Outlet</span>
          <span className="gray-color">20</span>
        </div>
        <Divider />
        <div className="d-flex j-between  p-15">
          <span className="gray-color">Healthy Furs Sand Canyon</span>
          <span className="gray-color">20</span>
        </div>
        <Divider />
        <div className="d-flex j-between  p-15">
          <span className="gray-color">Long Beach</span>
          <span className="gray-color">10</span>
        </div>
      </div>
    </>
  );
};

export default ProdInv;
